import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppState } from '@/store/store'
import { PropertyError } from '@/lib/formfactory/types' 
import _ from 'lodash';

type WizardStep = {
  code: string,
  step: number,
}

type EndpointWizardConfiguration = {
  states: string[][]
  hiddenFields: string[]
}

type WizardConfiguration = {
  lastStep: number,
  endpointConfiguration: { [key: string]: EndpointWizardConfiguration }
}

type EndpointStatus = {
  value: {},
  errors: PropertyError[],
  missingFields: string[],
  activeFields?: string[],
  hiddenFields?: string[],
}

interface WizardConfigurationPayload {
  configuration: { [key: string]: EndpointWizardConfiguration },
  lastStep: number
  code: string
}

interface DynamicMissingProperty {
  endpoint: string,
  label: string,
}

interface DynamicKey {
  endpoint: string,
  path: string,
}

interface DynamicValue extends DynamicKey {
  value: any
}

interface DynamicError extends PropertyError {
  endpoint: string
}

type DynamicFormState = {
  current: WizardStep,
  configuration: WizardConfiguration,
  endpoint: { [key: string]: EndpointStatus }
}

const initialState:DynamicFormState = {
  current: {
    code: '',
    step: 0,
  },
  configuration: {
    lastStep: 0,
    endpointConfiguration: {}
  },
  endpoint: {}
}

/**
 * Removes any number of points at the begningin of the path
 * @param path 
 * @returns 
 */
function removeStartingDots(path: string): string {
  let i = 0;
  while (path[i] === '.') i++;
  return path.slice(i);
}

// Slice
export const reportStepSlice = createSlice({
  name: 'dynamicForm',
  initialState,
  reducers: {
    setDynamicFormValue: (state, action: PayloadAction<DynamicValue>) => {
      if (state.endpoint[action.payload.endpoint] === undefined) state.endpoint[action.payload.endpoint] = { value: {}, errors: [], missingFields: [] };
      _.set(state.endpoint[action.payload.endpoint].value, removeStartingDots(action.payload.path), action.payload.value);
    },
    resetDynamicFormValue: (state, action: PayloadAction<DynamicKey>) => {
      if (state.endpoint[action.payload.endpoint] === undefined) return;
      _.unset(state.endpoint[action.payload.endpoint].value, removeStartingDots(action.payload.path));
    },
    resetDynamicForm: () => initialState,
    setWizardConfiguration: (state, action: PayloadAction<WizardConfigurationPayload>) => {
      
      state.configuration = {
        endpointConfiguration: action.payload.configuration,
        lastStep: action.payload.lastStep
      }
      state.current = {
        code: action.payload.code,
        step: 0,        
      }
      Object.keys(action.payload.configuration).forEach((key) => {
        state.endpoint[key] = {
          value: state.endpoint[key] !== undefined ? state.endpoint[key].value : {},
          errors: [],
          missingFields: [],
          activeFields: action.payload.configuration[key].states[0],
          hiddenFields: action.payload.configuration[key].hiddenFields
        }
      });    
    },
    backwardDynamicForm: (state) => {
      if (state.current.step === 0) return;

      const step = state.current.step - 1;
      state.current.step = step;
      
      Object.keys(state.configuration.endpointConfiguration).forEach((key) => {        
        state.endpoint[key].activeFields = state.configuration.endpointConfiguration[key].states[step];
        state.endpoint[key].hiddenFields = state.configuration.endpointConfiguration[key].hiddenFields;
        state.endpoint[key].errors = [];
        state.endpoint[key].missingFields = [];
      });
    },
    forwardDynamicForm: (state) => {
      if (state.current.step === state.configuration.lastStep) return;
      
      const step = state.current.step + 1;
      state.current.step = step;
      
      Object.keys(state.configuration.endpointConfiguration).forEach((key) => {        
        state.endpoint[key].activeFields = state.configuration.endpointConfiguration[key].states[step];
        state.endpoint[key].hiddenFields = state.configuration.endpointConfiguration[key].hiddenFields;
        state.endpoint[key].errors = [];
        state.endpoint[key].missingFields = [];
      });
    },
    setDynamicFormError: (state, action: PayloadAction<DynamicError>) => {
      if (state.endpoint[action.payload.endpoint] === undefined) return;

      let found = false;
      for (let i = 0; i < state.endpoint[action.payload.endpoint].errors.length; i++) {
        if (state.endpoint[action.payload.endpoint].errors[i].property === action.payload.property) {
          state.endpoint[action.payload.endpoint].errors[i] = action.payload;
          found = true;
        }
      }
      if (!found) state.endpoint[action.payload.endpoint].errors.push(action.payload);      
    },
    addDynamicFormMissingField: (state, action: PayloadAction<DynamicMissingProperty>) => {
      if (state.endpoint[action.payload.endpoint] === undefined) return;
      
      if (!state.endpoint[action.payload.endpoint].missingFields.includes(action.payload.label))
        state.endpoint[action.payload.endpoint].missingFields.push(action.payload.label);      
    },
    removeDynamicFormMissingField: (state, action: PayloadAction<DynamicMissingProperty>) => {
      if (state.endpoint[action.payload.endpoint] === undefined) return;
      
      state.endpoint[action.payload.endpoint].missingFields = state.endpoint[action.payload.endpoint].missingFields.filter((field) => field !== action.payload.label);      
    }
  }
})

export const selectDynamicFormValue = (endpoint: string) => (state: AppState) => state.dynamicForm.endpoint[endpoint]?.value;
export const selectDynamicFormValueByPath = (endpoint: string, path: string) => (state: AppState) => path !== '' ? _.get(state.dynamicForm.endpoint[endpoint]?.value, removeStartingDots(path)) : state.dynamicForm.endpoint[endpoint]?.value;
export const selectDynamicFormErrors = (endpoint: string) => (state: AppState) => (state.dynamicForm.endpoint[endpoint] !== undefined ? state.dynamicForm.endpoint[endpoint].errors : []) as PropertyError[];
export const selectDynamicFormMissingFields = (endpoint: string) => (state: AppState) => (state.dynamicForm.endpoint[endpoint] !== undefined ? state.dynamicForm.endpoint[endpoint].missingFields : []) as string[];

export const selectDynamicFormStep = (state: AppState): WizardStep | undefined => state.dynamicForm.current;
export const selectDynamicFormActiveFields = (endpoint: string) => (state: AppState) => (state.dynamicForm.endpoint[endpoint] !== undefined ? state.dynamicForm.endpoint[endpoint].activeFields : undefined) as string[] | undefined;
export const selectDynamicFormHiddenFields = (endpoint: string) => (state: AppState) => (state.dynamicForm.endpoint[endpoint] !== undefined ? state.dynamicForm.endpoint[endpoint].hiddenFields : undefined) as string[] | undefined;
export const { setDynamicFormValue, resetDynamicFormValue, forwardDynamicForm, backwardDynamicForm, resetDynamicForm, setDynamicFormError, 
               addDynamicFormMissingField, removeDynamicFormMissingField, setWizardConfiguration } = reportStepSlice.actions;

export default reportStepSlice.reducer;