import { FC, ReactNode, useEffect, useState } from 'react'
import clsx from 'clsx'

export type DisclaimerModalProps = {
    animation?: boolean
    extraClass?: string
    children?: ReactNode | ((hideModal: () => void) => ReactNode)
  }

const DisclaimerModal: FC<DisclaimerModalProps> = ( {animation = true, extraClass = '', children} ) => { 

    const [showDisclaimer, setShowDisclaimer] = useState(!animation)

    useEffect(() => {
        if (animation) {
            const timer = setTimeout(() => {
                setShowDisclaimer(true)
            }, 2000)
            return () => clearTimeout(timer)
        } 
    }, [])

    const hideModal = () => {
        setShowDisclaimer(false)
    }
    
    return (
        <div className={clsx('disclaimerModal', extraClass !== '' && extraClass, showDisclaimer && 'on')}>
            <div className="disclaimer-wrapper">
                {typeof children === 'function' ? children(hideModal) : children}
            </div>
        </div>
    )
}


export default DisclaimerModal