import { useLayoutEffect } from 'react'
import { useI18nContext } from '@/i18n/i18n-react'
import { useAuthenticator } from '@aws-amplify/ui-react'
import LinkButton from '../common/LinkButton'


export default function SignInFooter() {
  const { LL } = useI18nContext()
  const { toSignUp, toResetPassword } = useAuthenticator();

  useLayoutEffect(() => {
    const formEntity = document.querySelector('.authenticator-form form') as HTMLElement
    if (formEntity) {
      formEntity.id = 'formSignin'
    }
    
    const btForm = document.querySelector('.authenticator-form form button.amplify-button--primary') as HTMLElement
    if (btForm) {
      btForm.id = 'btSignin'
    }
  }, []);

  return (
    <div className='flex flex-row Oxion-small-regular justify-content-between sigin-footer'>
      <div className='flex align-items-center'>
        <span>{LL.signin.registerLabel()}</span>
        <LinkButton label={LL.signin.registerLink()} handleClick={() => toSignUp()} arrow={false} />
      </div>
      <div className='w-max'>
        <LinkButton label={LL.signin.forgotPasswordLink()} handleClick={() => toResetPassword()} arrow={false} />
      </div>
    </div>
  );
}