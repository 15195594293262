import { useEffect, useRef } from 'react'
import { Toast } from 'primereact/toast'
import { selectMessages, clearMessages } from '@/store/slice/messages'
import { useAppSelector, useAppDispatch } from '@/store/hooks'
import { useI18nContext } from '@/i18n/i18n-react'
import { evalOrDefault } from '@/lib/utils'

export default function ToastContainer() {
  const { LL } = useI18nContext();
  const dispatch = useAppDispatch();
  const messages = useAppSelector(selectMessages);
  
  const toast = useRef<Toast>(null);  
  
  useEffect(() => {
    if (messages.messages.length > 0) {
      messages.messages.forEach(msg => {
        toast.current?.show({
                              ...msg, 
                              // @ts-expect-error enable key use
                              summary: evalOrDefault(() => LL.messages[msg.summary].summary(), msg.summary), 
                              // @ts-expect-error enable key use
                              detail: evalOrDefault(() => LL.messages[msg.summary].detail(), msg.detail),
                              life: 15000,
                            });
      });
      dispatch(clearMessages());
    }
  }, [messages]);

  return (
    <Toast ref={toast} position="bottom-right" />
  );
} 