import { isFulfilled, isRejectedWithValue } from '@reduxjs/toolkit'
import type { MiddlewareAPI, Middleware } from '@reduxjs/toolkit'
import { pushMessage } from '@/store/slice/messages'


function handleFullfilledApiMutations(api: MiddlewareAPI, action:any){
  switch (action.meta.arg.endpointName) {
    case 'sendMail':
      api.dispatch(pushMessage({
        severity: 'success',
        summary: 'SEND_MAIL_SUCCESS',
      }));
      break;  
    case 'addNNS10Service':
      api.dispatch(pushMessage({
        severity: 'success',
        summary: 'ADD_SERVICE_SUCCESS',
      }));
      break;
    case 'promoteNNS10Service':
        api.dispatch(pushMessage({
          severity: 'success',
          summary: 'PROMOTE_SERVICE_SUCCESS',
        }));
        break;
    case 'addProject':
      api.dispatch(pushMessage({
        severity: 'success',
        summary: 'ADD_PROJECT_SUCCESS',
      }));
      break;
    case 'updateProject':
    case 'updateContactsProject':
    case 'addFile':
      api.dispatch(pushMessage({
        severity: 'success',
        summary: 'UPDATE_PROJECT_SUCCESS',
      }));
      break;
    case 'deleteProject':
      api.dispatch(pushMessage({
        severity: 'success',
        summary: 'DELETE_PROJECT_SUCCESS',
      }));
      break;
    default:
      break;
  }
}

/**
 * Show a toast!
 */
const showErrorToast = (api: MiddlewareAPI, action: any, summaryTitle: string) => {
  api.dispatch(pushMessage({
    severity: 'error',
    summary: summaryTitle,
    detail: action?.payload?.data?.message ?? '',
  }));
}

export const rtkQueryErrorLogger: Middleware =
  (api: MiddlewareAPI) => (next) => (action) => {

    if (isRejectedWithValue(action)) {
      let returnStatus:any = undefined
      if(action?.payload?.originalStatus !== undefined){
        returnStatus = action?.payload?.originalStatus;
      } else if (action?.payload?.status !== undefined){
        returnStatus = action?.payload?.status;
      }
      
      switch (returnStatus) {
          case 400:

            if (action?.meta.arg.endpointName === 'getNNS10MLSimulation') {
                api.dispatch(pushMessage({
                  severity: 'error',
                  summary: 'DESIGNEXPLORER_RESTRICTIONS'
                }));
            
            }else {
              const summaryTitle = action?.payload?.data?.message ? 'GENERIC_ERROR_CUSTOM_MESSAGE' : 'GENERIC_ERROR'
              showErrorToast(api, action, summaryTitle);
            }

            break;
          
          case 401:
            // No alert, incoming token expired, the app will renew the token directly
          break;

          case 403:
              api.dispatch(pushMessage({
                severity: 'warn',
                summary: 'INVALID_ACCESS',
              }));
              break;

          case 404:
              // No alert, we will redirect users to 404 page
              break;

          case 409:
              showErrorToast(api, action, 'GENERIC_ERROR_CUSTOM_MESSAGE');
              break;

          case 422:
            // Java validation ML Basetis -- No alert, we handle it in the response            
            break;

          case 429:
            api.dispatch(pushMessage({
              severity: 'warn',
              summary: 'TOO_MANY_REQUESTS',
            }));
            break;

          case 504:
             // Timeout API errors. No alert
            break;

          default: {
            const summaryTitle = action?.payload?.data?.message ? 'GENERIC_ERROR_CUSTOM_MESSAGE' : 'GENERIC_ERROR'
            showErrorToast(api, action, summaryTitle);
            break;
          }
        }
    } else if (isFulfilled(action) && action?.type) {
      switch (action.type) {
        case 'api/executeMutation/fulfilled':
          handleFullfilledApiMutations(api, action);
          break
        case 'setUser/fulfilled':
          api.dispatch(pushMessage({
            severity: 'success',
            summary: 'UPDATE_USER_SUCCESS',
          }));
          break;
        default:
          break;
      }            
    }

    return next(action)
  }