import React, { createContext, useState } from 'react'
import type { MenuContextProps } from '../../types/types'

export const MenuContext = createContext({} as MenuContextProps)

interface MenuProviderProps {
  children: React.ReactNode
}

export const MenuProvider = ({children}: MenuProviderProps) => {
  const [activeMenu, setActiveMenu] = useState('')

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const value = {
    activeMenu,
    setActiveMenu,
  }

  return (
    <MenuContext.Provider value={value}>{children}</MenuContext.Provider>
  );
};
