import { baseApi } from './_base'


export const featuresApi = baseApi.injectEndpoints({
    endpoints: builder => ({
        getFeatureFlags: builder.query<string, void>({
            query: () => ({
                url: 'feature',
                method: 'GET'
            }),
            providesTags: ['featureFlags'],
        }),
    })
  })
  
  export const {
    useGetFeatureFlagsQuery,
    useLazyGetFeatureFlagsQuery,
  } = featuresApi