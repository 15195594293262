import { FC, SyntheticEvent } from 'react'
import { IconButton } from '@/components/common/IconButton'
import Link from 'next/link'
import { MenuProvider } from './context/menucontext'
import AppMenu from './AppMenu'

type AppSidebarProps = {
  onChangeMenuMode: (event: SyntheticEvent<Element, Event>) => void;
}

const AppSidebar: FC<AppSidebarProps> = ({ onChangeMenuMode }) => {
  return (
    <>
      <div className="sidebar-header">
        <IconButton handleClick={onChangeMenuMode} />     
      </div>

      <div className="sidebar-logo">
        <Link href="/dashboard">
          <img className="logo_light" src="/images/logo/oxion_light.svg" alt='Oxion' />
          <img className="logo_dark" src="/images/logo/oxion_dark.svg" alt='Oxion' />
        </Link>
      </div>

      <div className="layout-menu-container">
        <MenuProvider>
          <AppMenu />
        </MenuProvider>
      </div>
    </>
  );
};

export default AppSidebar;
