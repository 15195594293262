import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppState } from '@/store/store'
import type { LayoutConfig, Setting } from '@/types/types'

const initialState = {
  ripple: true,
  inputStyle: 'outlined',
  menuMode: 'slim',
  menuTheme: 'colorScheme',
  colorScheme: 'light',
  theme: 'indigo',
  scale: 14,
}

// Slice
export const layoutConfigSlice = createSlice({
  name: 'layoutConfig',
  initialState,
  reducers: {
    setLayoutConfig: (state, action: PayloadAction<Setting>) => {
      return {...state, [action.payload.name]: action.payload.value};
    }
  }
})

export const selectLayoutConfig = (state: AppState): LayoutConfig => state.layoutConfig;
export const { setLayoutConfig } = layoutConfigSlice.actions;

export default layoutConfigSlice.reducer;