'use client'

import React, { useState } from 'react'
import { useAppSelector, useAppDispatch } from '@/store/hooks'
import { selectLayoutConfig, setLayoutConfig } from '@/store/slice/layoutConfig'
import type {
  ChildContainerProps,
  LayoutContextProps,
  LayoutState,
  Breadcrumb,
  Setting
} from '../../types/types'


export const LayoutContext = React.createContext({} as LayoutContextProps);

export const LayoutProvider = ({children}: ChildContainerProps) => {
  const dispatch = useAppDispatch()
  
  const [breadcrumbs, setBreadcrumbs] = useState<Breadcrumb[]>([])
  
  const layoutConfig = useAppSelector(selectLayoutConfig) 
  function dispatchSetLayoutConfig(setting: Setting) {
    dispatch(setLayoutConfig(setting))
  }

  const [layoutState, setLayoutState] = useState<LayoutState>({
    staticMenuDesktopInactive: false,
    overlayMenuActive: false,
    overlaySubmenuActive: false,
    profileSidebarVisible: false,
    configSidebarVisible: false,
    filterProjectSidebarVisible: false,
    filterUserSidebarVisible: false,
    infoUserSidebarVisible: false,
    staticMenuMobileActive: false,
    menuHoverActive: false,
    resetMenu: false,
    sidebarActive: false,
    anchored: false,
    showNotificationPopup: false,
  })

  const isOverlay = () => {
    return layoutConfig.menuMode === 'overlay'
  };

  const isSlim = () => {
    return layoutConfig.menuMode === 'slim'
  };

  const isSlimPlus = () => {
    return layoutConfig.menuMode === 'slim-plus'
  };

  const isHorizontal = () => {
    return layoutConfig.menuMode === 'horizontal'
  };

  const isDesktop = () => {
    return window.innerWidth > 991
  };

  const onMenuToggle = () => {
    if (isOverlay()) {
      setLayoutState((prevLayoutState) => ({
        ...prevLayoutState,
        overlayMenuActive: !prevLayoutState.overlayMenuActive,
      }))
    }

    if (isDesktop()) {
      setLayoutState((prevLayoutState) => ({
        ...prevLayoutState,
        staticMenuDesktopInactive: !prevLayoutState.staticMenuDesktopInactive,
      }))
    } else {
      setLayoutState((prevLayoutState) => ({
        ...prevLayoutState,
        staticMenuMobileActive: !prevLayoutState.staticMenuMobileActive,
      }))
    }
  };

  const showProfileSidebar = () => {
    setLayoutState((prevLayoutState) => ({
      ...prevLayoutState,
      profileSidebarVisible: !prevLayoutState.profileSidebarVisible,
    }))
  };

  const showFilterProjectSidebar = () => {
    setLayoutState((prevLayoutState) => ({
      ...prevLayoutState,
      filterProjectSidebarVisible: !prevLayoutState.filterProjectSidebarVisible,
    }))
  };
  const showFilterUserSidebar = () => {
    setLayoutState((prevLayoutState) => ({
      ...prevLayoutState,
      filterUserSidebarVisible: !prevLayoutState.filterUserSidebarVisible,
    }))
  };

  const showInfoUserSidebar = () => {
    setLayoutState((prevLayoutState) => ({
      ...prevLayoutState,
      infoUserSidebarVisible: !prevLayoutState.infoUserSidebarVisible,
    }))
  };

  const setNotificationPopupToggle = (state: boolean) => {
    setLayoutState((prevLayoutState) => ({
      ...prevLayoutState,
      showNotificationPopup: state,
    }))
  };

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const value = {
    layoutConfig,
    setLayoutConfig: dispatchSetLayoutConfig,
    layoutState,
    setLayoutState,
    onMenuToggle,
    showProfileSidebar,
    showFilterProjectSidebar,
    showInfoUserSidebar,
    showFilterUserSidebar,
    isSlim,
    isSlimPlus,
    isHorizontal,
    isDesktop,
    breadcrumbs,
    setBreadcrumbs,
    setNotificationPopupToggle,
  }

  return (
    <LayoutContext.Provider value={value}>
      {children}
    </LayoutContext.Provider>
  );
};
