import { useContext, useEffect, useRef } from 'react'
import Link from 'next/link'
import { usePathname, useSearchParams } from 'next/navigation'
import { classNames } from 'primereact/utils'
import { IconButton } from '@/components/common/IconButton'
import { MenuButton } from '@/components/common/MenuButton'
import { AppMenuItem } from '@/types/layout'
import { LayoutContext } from './context/layoutcontext'
import { MenuContext } from './context/menucontext'
import { useSubmenuOverlayPosition } from './hooks/useSubmenuOverlayPosition'
import type { AppMenuItemProps } from '../types/types'

const AppMenuitem = (props: AppMenuItemProps) => {
  const pathname = usePathname()
  const searchParams = useSearchParams()

  const { activeMenu, setActiveMenu } = useContext(MenuContext)
  const { isSlim, isSlimPlus, isHorizontal, isDesktop, setLayoutState,
    layoutState, layoutConfig, } = useContext(LayoutContext)
  const submenuRef = useRef<HTMLUListElement>(null)
  const menuitemRef = useRef<HTMLLIElement>(null)

  const { item, parentKey, index, root } = props

  const key = parentKey ? `${parentKey}-${index}` : String(index)
  const isActiveRoute = item!.to && (pathname === item!.to || pathname?.includes(`${item?.to}/`))
  const active = activeMenu === key || !!(activeMenu?.startsWith(`${key}-`))
  useSubmenuOverlayPosition({
    target: menuitemRef.current,
    overlay: submenuRef.current,
    container: menuitemRef?.current?.closest('.layout-menu-container') ?? null,
    when: root && active && (isSlim() || isSlimPlus() || isHorizontal()) && isDesktop(),
  })

  useEffect(() => {
    if (layoutState.resetMenu) {
      setActiveMenu('')
      setLayoutState((prevLayoutState) => ({ ...prevLayoutState, resetMenu: false, }))
    }
  }, [layoutState.resetMenu])

  useEffect(() => {
    if (!(isSlim() || isSlimPlus() || isHorizontal()) && isActiveRoute) {
      setActiveMenu(key)
    }
    const url = pathname + searchParams.toString()
    const onRouteChange = () => {
      if (!(isSlim() || isHorizontal()) && item!.to && item!.to === url) {
        setActiveMenu(key)
      }
    };
    onRouteChange()
  }, [pathname, searchParams, layoutConfig])

  const itemClickToggleActiveState = () => {
    if (item?.items) {
      setActiveMenu(active ? parentKey! : key);

      if (
        root &&
        !active &&
        (isSlim() || isHorizontal() || isSlimPlus())
      ) {
        setLayoutState((prevLayoutState) => ({
          ...prevLayoutState,
          overlaySubmenuActive: true,
        }))
      }
    } else {
      if (!isDesktop()) {
        setLayoutState((prevLayoutState) => ({
          ...prevLayoutState,
          staticMenuMobileActive: !prevLayoutState.staticMenuMobileActive,
        }))
      }

      if (isSlim() || isSlimPlus() || isHorizontal()) {
        setLayoutState((prevLayoutState) => ({
          ...prevLayoutState,
          menuHoverActive: false,
        }))
      }

      setActiveMenu(key)
    }
  }

  const itemClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    // avoid processing disabled items
    if (item!.disabled) {
      event.preventDefault()
      return
    }

    // navigate with hover
    if (root && (isSlim() || isHorizontal() || isSlimPlus())) {
      const isSubmenu =
        event.currentTarget.closest(
          '.layout-root-menuitem.active-menuitem > ul'
        ) !== null;
      if (isSubmenu)
        setLayoutState((prevLayoutState) => ({ ...prevLayoutState, menuHoverActive: true, }));
      else
        setLayoutState((prevLayoutState) => ({ ...prevLayoutState, menuHoverActive: !prevLayoutState.menuHoverActive, }));
    }

    // execute command
    if (item?.command) 
      item?.command({ originalEvent: event, item })    

    itemClickToggleActiveState()
  }

  const onMouseEnter = () => {
    // activate item on hover
    if (
      root &&
      (isSlim() || isHorizontal() || isSlimPlus()) &&
      isDesktop()
    ) {
      if (!active && layoutState.menuHoverActive) {
        setActiveMenu(key);
      }
    }
  };

  const subMenu =
    item?.items && item?.visible !== false ? (
      <ul ref={submenuRef}>
        {item?.items.map((child, i) => {
          return (
            <AppMenuitem item={child} index={i} className={child.badgeClass} parentKey={key} key={child.label} />
          );
        })}
      </ul>
    ) : null;

  const renderVisualComponent = (item:AppMenuItem) => {
    const buttonIcon = isActiveRoute && item?.iconActive ? item?.iconActive : item?.icon
    
    return isSlim() 
      ? <IconButton handleClick={() => {}} selected={isActiveRoute || false} >{buttonIcon}</IconButton>
      : <MenuButton label={item?.label} selected={isActiveRoute || false} handleClick={() => {}} >{buttonIcon}</MenuButton>
  }

  return (
    <li ref={menuitemRef} className={classNames({ 'layout-root-menuitem': root, 'active-menuitem': active, 'disabled-menuitem' : item?.disabled })} >
      {(!item?.to || item?.items) && item?.visible !== false ? (
        <a href={item?.url} onClick={(e) => itemClick(e)} className={classNames(item?.class, 'tooltip-target', { 'active-route': isActiveRoute, })} 
            data-pr-tooltip={item?.tooltip ?? item?.label} data-pr-at="right+31 center" data-pr-disabled={ item?.disabled ? false : !(isSlim() && root && !layoutState.menuHoverActive) }
            tabIndex={0} onMouseEnter={onMouseEnter} target={item?.target} >
            { renderVisualComponent(item!) }
        </a>
      ) : null}

      {item?.to && !item?.items && item?.visible !== false ? (
        <Link href={item?.to} onClick={(e) => itemClick(e)} className={classNames(item?.class, 'tooltip-target', { 'active-route': isActiveRoute, })}
              data-pr-tooltip={item?.tooltip ?? item?.label} data-pr-at="right+31 center" data-pr-disabled={ item?.disabled ? false : !(isSlim() && root && !layoutState.menuHoverActive) }
              tabIndex={0} onMouseEnter={onMouseEnter} replace={item?.replaceUrl} >
            { renderVisualComponent(item!) }
        </Link>
      ) : null}
      {subMenu}
    </li>
  );
};

export default AppMenuitem;
