import { TranslationFunctions } from '@/i18n/i18n-types'
import { isPossiblePhoneNumber } from 'react-phone-number-input'

export interface ValidationError {
  error?: boolean,
  msg: string
}

/**
 * Validator function, returns TRUE if validation is OK, FALSE otherwise
 */
type Validator = (value:any, setter:ErrorSetter, options?:ValidationOptions) => ValidationError;

export interface ValidationOptions {
  nextOptions?: ValidationOptions
  next?: Validator
}

export type ErrorSetter = (value:ValidationError) => void;

export function validate(value:any, setter:ErrorSetter, options:ValidationOptions[]) : ValidationError {
  if (options.length === 0) {
    const error = {error: false, msg:''}
    setter(error);
    return error;
  }    
  
  let error:ValidationError = {error: false, msg:''};
  const onError = (value:ValidationError) => {
    error = value;
    setter(value);      
  }

  for (const element of options) {
    if (error.error)
      break;
    else if (element.next !== undefined)
      element.next(value, onError, element.nextOptions);    
  }
  
  return error;
}

export function continueIfRequired(value:any, setter:(value:ValidationError) => void, options?:ValidationOptions) : ValidationError {
  if (options?.next !== undefined) {
    options.next(value, setter, options.nextOptions);
  } 
  
  const error = {error: false, msg:''}
  setter(error);
    
  return error;
}

export function validateRequired(value:any, setter:(value:ValidationError) => void, options?:ValidationOptions) : ValidationError {
  if (value.length === 0 || value === null || value === undefined) {
    const error = {error: true, msg: 'required'};
    setter(error);
    return error;
  } 
    
  return continueIfRequired(value, setter, options)
}

export interface ValidationRegexpOptions extends ValidationOptions {
  msg?: string
  regexp?: RegExp
}

export function validateRegExp(value:string, setter:(value:ValidationError) => void, options?:ValidationRegexpOptions) : ValidationError {
  if (!options?.regexp?.test(value)) {
    const error = {error: true, msg: options?.msg ?? 'match'}
    setter(error);
    return error;
  } 
    
  return continueIfRequired(value, setter, options)
}

export function validateEmail(value:string, setter:(value:ValidationError) => void, options?:ValidationOptions) : ValidationError {
  const re = /^[\w-.]+@([\w-]+\.)+[\w-]+$/;
  return validateRegExp(value, setter, {regexp: re, msg: 'invalidEmail', ...options});
}

export interface ValidationLengthOptions extends ValidationOptions {
  min?: number,
  max?: number
}

export function validateLength(value:any, setter:(value:ValidationError) => void, options?:ValidationLengthOptions) : ValidationError {
  if (value.length < (options?.min ?? 0) || value.length > (options?.max ?? Number.MAX_VALUE)) {
    const error = {error: true, msg: 'invalidLength'}
    setter(error);
    return error;
  } 
    
  return continueIfRequired(value, setter, options)
}

interface ValidationEqualsOptions extends ValidationOptions {
  value2: any
}

export function validateEquals(value:any, setter:any, options?:ValidationEqualsOptions) : ValidationError {
   if (value !== options?.value2) {
    const error = {error: true, msg: 'match'};
    setter(error);
    return error;
  } 
    
  return continueIfRequired(value, setter, options)
}

export interface ValidationNumberOptions extends ValidationOptions {
  min?: number,
  max?: number,
  errorMessage?: string,
}

export function validateNumber(value:any, setter:(value:ValidationError) => void, options?:ValidationNumberOptions) : ValidationError {
  if (value < (options?.min ?? Number.MIN_VALUE) || value > (options?.max ?? Number.MAX_VALUE)) {
    const error = {error: true, msg: options?.errorMessage && options?.errorMessage !== '' ? options?.errorMessage : 'invalidNumber'}
    setter(error)
    return error
 } 
   
 return continueIfRequired(value, setter, options)
}

export function validatePhoneNumber(value:any, setter:(value:ValidationError) => void, options?:ValidationOptions) : ValidationError {
  if (isPossiblePhoneNumber(value) === false) {
    const error = {error: true, msg: 'invalidPhoneNumber'}
    setter(error)
    return error
 } 
   
 return continueIfRequired(value, setter, options)
}


export function validateStrongPassword(value:string, setter:any, options?:ValidationOptions) : ValidationError {
  const re = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\W_]).{8,}$/
  return validateRegExp(value, setter, {regexp: re, msg: 'invalidPassword', ...options})
}

export const isValidEmail = (email: string): boolean => {
  // Regular expression for validating email addresses
  const emailRegex: RegExp = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
  
  return emailRegex.test(email)
}

export const isValidNumber = (cadena: string): boolean => {
  return /^\d+$/.test(cadena)
}

export const isValidFloat = (cadena: string): boolean => {
  return /^\d+(\.\d+)?$/.test(cadena)
}

const isSignUpMissingFields = (formData: any): boolean => {
  if (formData.given_name === undefined || formData.family_name === undefined || formData.email === undefined || formData.password === undefined || formData.confirm_password === undefined) {
    return true
  }

  const inputCompany = document.querySelector('input[name="custom:companyName"]') as HTMLSelectElement;
  if ((inputCompany) && (inputCompany.value === '' || inputCompany.value === undefined)) {
    return true
  }

  const selectCountry = document.querySelector('select[name="custom:country"]') as HTMLSelectElement;
  if ((selectCountry) && (selectCountry.value === '' || selectCountry.value === undefined)) {
    return true
  }

  const selectRole = document.querySelector('select[name="custom:role"]') as HTMLSelectElement;
  if ((selectRole) && (selectRole.value === '' || selectRole.value === undefined)) {
    return true
  }
  
  return false;
}



export const getErrorFromSignUp = (formData: any, LL:TranslationFunctions): string => {

  if (isSignUpMissingFields(formData)) {
    return LL.error.requiredAll()
  }
  
  if (!isValidEmail(formData.email)) {
    return LL.error.invalidEmail()
  }

  if (!formData.consentdata) {
    return LL.signup.consentDataError()
  }

  if (!formData.acknowledgement) {
    return LL.signup.termsError()
  }

  return ''
}

export const getPasswordErrorFromSignUp = (formData: any, LL:TranslationFunctions): string => {
  if (formData.password !== formData.confirm_password) {
    return LL.error.invalidPasswordMatch()
  }

  if (formData.password !== undefined && !formData.password.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\W_]).{8,}$/)) {
    return LL.error.invalidPasswordStrength()
  }

  return ''
}