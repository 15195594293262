import { FC } from 'react'

const VideoAurora: FC = () => {  
    
    return (
        <video autoPlay muted loop playsInline poster='/images/aurora-stillframe.png' src='/vid/aurora-background.mp4'>
           <source src="/vid/aurora-background.mp4" type="video/mp4" />
        </video>
    )
}


export default VideoAurora