import { FC, ReactNode } from 'react'
import clsx from 'clsx'
import { IconToggle } from '../IconButton/consts'

export type IconButtonProps = {
    label: string
    disabled?: boolean
    selected?: boolean
    handleClick: Function
    children?: ReactNode
}

const MenuButton: FC<IconButtonProps> = ({ label, handleClick, disabled = false, selected = false, children }) => {     
    return (
        <div className={clsx('menu-button', disabled && 'disabled', selected && 'selected')} data-testid='menu-button'>
        <button onClick={() => handleClick()}>
            { children ?? <IconToggle /> }
            <span>{label}</span>
            {selected && <img className="menu-arrow-selected" src="/images/right-arrow.svg" alt='arrow' /> }
        </button>
        </div>       
    )
}


export default MenuButton