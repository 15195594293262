import { useEffect, ReactNode } from 'react'
import { useAppDispatch, useAppSelector } from '@/store/hooks'
import TypesafeI18n from '@/i18n/i18n-react'
import { loadAllLocales } from '@/i18n/i18n-util.sync'
import { useAuthenticator } from '@aws-amplify/ui-react'
import { Locales } from '@/i18n/i18n-types'
import { clearStore, selectCurrentVersion } from '@/store/slice/version'
import { getParsedJson } from '@/store/slice/parser'
import { useLocalStorage } from '@/hooks/useLocalStorage'
import { useLazyGetFeatureFlagsQuery } from '@/store/api/ignion/features'
import { isFeatureAvailable, setAllFeatures } from '@/store/slice/featureFlags'
import { useUserInteraction } from '@/hooks/useUserInteraction'
import { useLazyGetAllInteractionsQuery } from '@/store/api/ignion/userInteractions'

loadAllLocales()

type AppConfigProps = {
  children?: ReactNode
}

const version = process.env.NEXT_PUBLIC_VERSION ?? '0.0.1';

export default function AppConfig({children} : Readonly<AppConfigProps>) {
  
  const dispatch = useAppDispatch();
  const { user } = useAuthenticator(context => [context.user]);
  const storeVersion = useAppSelector(selectCurrentVersion);

  const { setItemUnderKey, removeItem } = useLocalStorage('OxionStorage')
  
  const [ getFeatureFlags ] = useLazyGetFeatureFlagsQuery();

  const FF_1_3 = useAppSelector(isFeatureAvailable('version_1_3'))

  const { checkNPSVisbility } = useUserInteraction()

  const [getUserInteractions] = useLazyGetAllInteractionsQuery();

  useEffect(() => {
    if (storeVersion !== version) {
      dispatch(clearStore())
      dispatch(getParsedJson())
      removeItem()
    }
  }, [])

  useEffect(() => {
    if (user !== undefined) {
      getUserInteractions().unwrap()
        .then((res) => {

          if (res && res.length > 0) {
            const userInteractions = res[0].interactions
            userInteractions.map((interaction) => setItemUnderKey(interaction.id, JSON.parse(interaction.value)))
          }
          
        });

        getFeatureFlags().unwrap()
        .then((res) => {
            
            const flags = res 
            ? Object.entries(res).reduce(
                (acc, [key, value]) => ({
                    ...acc,
                    [key]: JSON.parse(value),
                }),
                {} as Record<string, boolean>
            )
            : {};

            dispatch(setAllFeatures(flags));
      });
    }
  } , [user])


  useEffect(() => {
    if (FF_1_3) {
      checkNPSVisbility()
    }
    
  }, [FF_1_3])

  const locale = (user?.attributes?.locale !== undefined ? user?.attributes?.locale : 'en') as Locales
  
  return (
    <TypesafeI18n locale={locale}>
      {children}
    </TypesafeI18n>
  );
} 