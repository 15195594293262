import { Message, NotificationMessageData } from '@/types/response/messages'
import { useI18nContext } from '@/i18n/i18n-react'
import NotificationMessage from '../NotificationMessage'

type WelcomeNotificationProps = {
  notification: Message<NotificationMessageData>;
};

export default function WelcomeNotification({ notification }: Readonly<WelcomeNotificationProps>) {

  const { LL } = useI18nContext();

  return (
    <NotificationMessage notification={notification}>
      <div className="notification-text">{LL.message.notification.welcome()}</div>
    </NotificationMessage>
  )
}