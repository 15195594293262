import { Message, ServiceNotificationMessageData } from '@/types/response/messages'
import { useI18nContext } from '@/i18n/i18n-react'
import NotificationMessage from '../NotificationMessage'

type ServiceDenyNotificationProps = {
  notification: Message<ServiceNotificationMessageData>;
};

export default function ServiceDenyNotification({ notification }: Readonly<ServiceDenyNotificationProps>) {

  const { LL } = useI18nContext();

  return (
    <NotificationMessage notification={notification}>
      <div className="notification-text">{LL.message.notification.serviceDeny()}</div>
    </NotificationMessage>
  )
}