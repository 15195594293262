import './globals.css'
import React, { FC } from 'react'
import { Provider } from 'react-redux'
import { AppProps } from 'next/app'
import { PrimeReactProvider } from 'primereact/api'
import { PersistGate } from 'redux-persist/integration/react'

import Layout from '@/layout/layout'
import { Authenticator } from '@aws-amplify/ui-react'

import 'primereact/resources/primereact.css'
import 'primeflex/primeflex.css'
import 'primeicons/primeicons.css'
import '@/styles/layout/layout.scss'

import AppConfig from '@/components/AppConfig'
import AppAuthentication from '@/components/auth/AppAuthentication'
import NextNProgress from 'nextjs-progressbar'
import NProgress from 'nprogress'
import { NextPageWithLayout } from '@/types/pages'
import { colorBranding } from '@/types/common'
import { DisclaimerMobile } from '@/components/layout/DisclaimerMobile'
import { wrapper } from '../store/store'
import { LayoutProvider } from '../layout/context/layoutcontext'

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout
}

const MyApp: FC<AppProps> = ({ Component, pageProps }: AppPropsWithLayout) => {
  const { store, props } = wrapper.useWrappedStore(pageProps);

  
  const getLayout = Component.getLayout ?? ((page) =>
    <LayoutProvider>
      <Layout>
        {page}
      </Layout>
    </LayoutProvider>)

  NProgress.configure({ showSpinner: false })

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={store.__persistor}>
        <PrimeReactProvider >
          <Authenticator.Provider>
            <AppConfig>
              <DisclaimerMobile>
                <AppAuthentication>
                  {getLayout(
                    <>
                      <NextNProgress showOnShallow stopDelayMs={1000} color={colorBranding.BLUE} />
                      <Component {...props.pageProps} />
                    </>)}
                </AppAuthentication>
              </DisclaimerMobile>
            </AppConfig>
          </Authenticator.Provider>
        </PrimeReactProvider>
      </PersistGate>
    </Provider>
  );
};

export default MyApp;