import { FC, ReactNode, SyntheticEvent, useRef } from 'react'
import clsx from 'clsx'
import { Tooltip } from 'primereact/tooltip'
import { capitalizeWords } from '@/lib/strings'
import { IconToggle } from './consts'

export type IconButtonProps = {
    id?: string
    disabled?: boolean
    selected?: boolean
    handleClick: (event: SyntheticEvent<Element, Event>) => void
    tooltip?: boolean
    tooltipLabel?: string
    tooltipPosition?: 'top' | 'left' | 'bottom' | 'right'
    children?: ReactNode
}


const IconButton: FC<IconButtonProps> = ({ id, handleClick, disabled = false, selected = false, tooltip = false, tooltipLabel, tooltipPosition = 'bottom', children }) => {  
    
    const tooltipRef = useRef<Tooltip | null>(null);

    const stringWithoutSpaces:string = capitalizeWords(tooltipLabel ?? '')
    const stringWithoutPointsAndCommas = stringWithoutSpaces.replace(/[.,]/g, '');
    
    return (
        <div data-testid={id} className={clsx(`icon-button icon-button-tooltip-${stringWithoutPointsAndCommas}`, disabled && 'disabled', selected && 'selected')} >
        <button id={id ?? ''} onClick={(e) => handleClick(e)}>
            { children ?? <IconToggle /> }
        </button>
        { tooltip && tooltipLabel && tooltipLabel !== '' && <Tooltip position={tooltipPosition} ref={tooltipRef} target={`.icon-button-tooltip-${stringWithoutPointsAndCommas}`}>{tooltipLabel}</Tooltip> }
        </div>       
    )
}


export default IconButton