import { forwardRef, useContext, useEffect, useImperativeHandle, useRef } from 'react'
import { PrimeReactContext } from 'primereact/api'
import { Button } from 'primereact/button'
import { Auth } from '@aws-amplify/auth'
import { useI18nContext } from '@/i18n/i18n-react'
import { useAuthenticator } from '@aws-amplify/ui-react'
import { useRouter } from 'next/router'
import { isFeatureAvailable, isTriggerActionAvailable } from '@/store/slice/featureFlags'
import { useAppSelector } from '@/store/hooks'
import { OverlayPanel } from 'primereact/overlaypanel'
import Notifications from '@/components/message/Notifications'
import { useLazyGetAllMessagesByQuery } from '@/store/api/ignion/message'
import { MessageType } from '@/types/response/messages'
import { IconButton } from '@/components/common/IconButton'
import { IconLogout, IconNotifications, IconSettings, IconView } from '@/components/common/IconButton/consts'
import { UserTopBarButton } from '@/components/account/UserTopBarButton'
import { DisclaimerModal } from '@/components/layout/DisclaimerModal'
import { Nps } from '@/components/layout/Nps'
import { ContextualMenu } from '@/components/layout/ContextualMenu'
import { TranslationFunctions } from '@/i18n/i18n-types'
import { ProjectMenuOptions } from '@/types/project'
import { Dialog } from 'primereact/dialog'
import { MainButton } from '@/components/common/MainButton'
import type { AppTopbarRef, ColorScheme } from '../types/types'
import { LayoutContext } from './context/layoutcontext'

const AppTopbar = forwardRef<AppTopbarRef>((props, ref) => {
  const { layoutConfig, layoutState, onMenuToggle, setLayoutConfig, setNotificationPopupToggle } = useContext(LayoutContext);
  const router = useRouter();
  const menubuttonRef = useRef(null);
  const accountMenu = useRef<OverlayPanel>(null);
  const op = useRef<OverlayPanel>(null);  
  const { LL } = useI18nContext()
  const { changeTheme } = useContext(PrimeReactContext);
  
  const [getMessages, messages] = useLazyGetAllMessagesByQuery();

  const featureFlagMessages = useAppSelector(isFeatureAvailable('accountMessages'))
  const askNPS = useAppSelector(isTriggerActionAvailable('NPS_SURVEY'))

  const changeColorScheme = (colorScheme: ColorScheme) => {
    changeTheme?.(layoutConfig.colorScheme, colorScheme, 'theme-link', () => {
      setLayoutConfig({ name: 'colorScheme', value: colorScheme });
    });
  };

  const { user } = useAuthenticator(context => [context.user]);

  useEffect(() => {
    getMessages({type: MessageType.NOTIFICATION, limit: 5})
    const updateMessages = setInterval(async () => {
        await getMessages({type: MessageType.NOTIFICATION, limit: 5}).unwrap()
    }, 60000);

    return () => clearInterval(updateMessages);
  }, []);

  useImperativeHandle(ref, () => ({
    menubutton: menubuttonRef.current,
  }))

  function buildProjectMenuModel(LL:TranslationFunctions): ProjectMenuOptions[] {
    return [
      {
        label: LL.menu.top.accountSettings(),
        icon: <IconSettings />,
        command: () => router.push('/account', undefined, { shallow: true }),
      },
      {
        label: LL.menu.top.messages(),
        icon: <IconView />,
        visible: featureFlagMessages,
        extraClass: featureFlagMessages ? 'disabled' : '',
        command: () => { }
      },
      {
        label: LL.menu.top.logOut(),
        icon: <IconLogout />,
        extraClass: 'separator',
        command: () => {
          Auth.signOut();
         }
      },
    ];
  }

  const accountMenuItems:ProjectMenuOptions[] = buildProjectMenuModel(LL)

  return (
    <>
    <div className="layout-topbar">
      <div className="topbar-start">
        <button ref={menubuttonRef} type="button" className="block md:hidden topbar-menubutton p-link p-trigger" onClick={onMenuToggle} aria-label='Toggle' >
          <span className="ms ms-menu" />
        </button>
      </div>

      <div className="topbar-end">
        <ul className="topbar-menu">
          <li>
            <Button type="button" icon={layoutConfig.colorScheme === 'dark' ? 'ms ms-light-mode' : 'ms ms-dark-mode'}
              text rounded severity="info" className="no-animation flex-shrink-0"
              onClick={() => changeColorScheme(layoutConfig.colorScheme === 'dark' ? 'light' : 'dark')} style={{ display: 'none' }} />
          </li>
          <li className='notification-icon'>
            { messages.data && messages.data.items.filter(item => !item.read).length > 0 && 
              <div className='notificationBadge'>{messages.data.items.filter(item => !item.read).length}</div> 
            }
            
            <IconButton id='buttonNotifications' handleClick={() => op.current?.toggle(undefined, document.getElementById('buttonNotifications'))}>
              <IconNotifications />
            </IconButton>
            <OverlayPanel ref={op}>
              <Notifications onClose={() => op.current?.hide()} />
            </OverlayPanel>
          </li>
          <li className="topbar-profile">
            <UserTopBarButton label={`${user?.attributes?.given_name} ${user?.attributes?.family_name}`} onClick={(event) => accountMenu.current?.toggle(event)} />
            <OverlayPanel ref={accountMenu} className='overlay-blur'>
                <ContextualMenu optionsMenuItems={accountMenuItems} reference={accountMenu} />
              </OverlayPanel>
          </li>
        </ul>
      </div>
    </div>

    <Dialog className='project-dialog release-notes' header={`What's new in version ${process.env.NEXT_PUBLIC_VERSION}`} visible={layoutState.showNotificationPopup} style={{ width: '60vw', maxWidth: '700px', maxHeight: '90vh' }} onHide={() => setNotificationPopupToggle(false)}
        draggable={false} dismissableMask={false} >
          <>
              <h4 className='Oxion-medium-sBold'>Version highlights:</h4>
              <ul className='Oxion-body-regular'>
                  <li><strong className='Oxion-body-sBold'>Share your feedback:</strong> We value your expertise. Share your thoughts on Oxion™ and its functionalities to help shape the platform&apos;s future.</li>
                  <li><strong className='Oxion-body-sBold'>Expanded distributor network:</strong> Our stock availability feature now spans our entire distributor network, ensuring real-time access to the components you need.</li>
                  <li><strong className='Oxion-body-sBold'>Enhanced guidance:</strong> Enjoy clearer guidance and tailored support through the UI functionalities.</li>
                  <li><strong className='Oxion-body-sBold'>Learn about antennas:</strong> Access our comprehensive MOOC on antennas, RF, and Virtual Antenna® technology, created in partnership with La Salle Ramon Llull University (Barcelona).</li>
              </ul>
              
              <h4 className='Oxion-medium-sBold'>More features</h4>
              <ul className='Oxion-body-regular'>
                  <li><strong className='Oxion-body-sBold'>Enhanced performance:</strong> Bug fixing.</li>
              </ul>
              <MainButton label='Got it' handleClick={() => setNotificationPopupToggle(false)} />
          </>
      </Dialog>

    {
      askNPS && 
            <DisclaimerModal extraClass='nps-disclaimer'>
              {hideModal => <Nps hideModal={hideModal} />}
            </DisclaimerModal>
    }
    </>
  );
});

AppTopbar.displayName = 'AppTopbar';

export default AppTopbar;
