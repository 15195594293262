import { FC, useState } from 'react'
import { useI18nContext } from '@/i18n/i18n-react'
import { Authenticator, CheckboxField, useAuthenticator } from '@aws-amplify/ui-react'
import { Dropdown } from 'primereact/dropdown'
import { UserRoles } from '@/types/user'
import { navigateToExternalUrl } from '@/lib/utils'
import countryData from '@/data/countries.json'
import LinkButton from '../common/LinkButton'
import InputContainer from '../InputContainer'

type SignUpFormFieldsProps = {
  twoColumns?: boolean
}

const SignUpFormFields: FC<SignUpFormFieldsProps> = ({ twoColumns = false }) => {
  const { LL } = useI18nContext()
  const { validationErrors } = useAuthenticator();

  const [selectedRole, setSelectedRole] = useState('');
  const [selectedCountry, setSelectedCountry] = useState('');

  const renderLastFields = () => {
    return <>
      <div className="flex w-full two-columns">
          <InputContainer id='custom:country' labelText={LL.signup.countryLabel()} >
            <Dropdown id="custom:country" name='custom:country' options={countryData.countries} required
              value={selectedCountry} placeholder={LL.signup.countryPlaceholder()} onChange={(e) => setSelectedCountry(e.target.value)} panelStyle={{maxWidth: 250, textOverflow: 'ellipsis'}} />
          </InputContainer>
          
          <InputContainer id='custom:role' labelText={LL.account.details.role.label()} >
            <Dropdown id="custom:role" name='custom:role' options={[UserRoles.ENGINEER, UserRoles.ENGINEERING_MANAGER, UserRoles.PRODUCT_MANAGER, UserRoles.BUSINESS_DIRECTOR, UserRoles.OTHER]}
              value={selectedRole} placeholder={LL.signup.rolePlaceholder()} onChange={(e) => setSelectedRole(e.target.value)} />
          </InputContainer>
        </div>
        <input id="locale" name="locale" value="en" type="hidden" />
      </>
  }

  const renderLegal = () => {
    return <>
      <div className="w-full checkPrivacy">
        <h4 className="Oxion-body-medium">{LL.signup.termsPolicy.title()}</h4>
        <p className="Oxion-xsmall-regular">{LL.signup.termsPolicy.dataController()}</p>
        <p className="Oxion-xsmall-regular">{LL.signup.termsPolicy.purpose()}</p>
        <p className="Oxion-xsmall-regular">{LL.signup.termsPolicy.rights()} <LinkButton label={LL.signup.privacyPolicy()} handleClick={() => navigateToExternalUrl('https://ignion.io/privacy-policy/')} arrow={false} /></p>

        <div className='flex w-full align-items-center gap-2 checkSignUp'>
        <CheckboxField errorMessage={validationErrors.consentdata as string} 
            name="consentdata" value="yes" label={LL.signup.terms()} required labelHidden />
            <label htmlFor="consentdata" className="Oxion-xsmall-regular flex align-items-center">
              {LL.signup.termsPolicy.agree()}
            </label>      
        </div>
      </div>

      <div className='flex w-full align-items-center gap-2 checkSignUp '>
      <CheckboxField errorMessage={validationErrors.acknowledgement as string} 
          name="acknowledgement" value="yes" label={LL.signup.terms()} required labelHidden />
          <label htmlFor="acknowledgement" className="Oxion-xsmall-regular flex align-items-center">
            {`${LL.signup.termsSubject()}`} 
            <LinkButton label={LL.signup.terms()} handleClick={() => navigateToExternalUrl('https://ignion.io/terms-of-use/')} arrow={false} />
          </label>      
      </div>
    </> 
  }

  return (
    <>
      { twoColumns ? 
        <div className="flex w-full two-columns-form">
          <div className="left">
            <Authenticator.SignUp.FormFields />
            { renderLastFields() }
          </div>
          <div className="right">
            { renderLegal() }
            {!!validationErrors.acknowledgement && <div className="amplify-flex amplify-field amplify-checkboxfield">
              <p className="w-full amplify-text amplify-field__error-message">{validationErrors.acknowledgement}</p>
            </div> }
          </div>
        </div> 
        : <>
          <Authenticator.SignUp.FormFields />
          { renderLastFields() }
          { renderLegal() }
          {!!validationErrors.acknowledgement && <div className="amplify-flex amplify-field amplify-checkboxfield">
            <p className="w-full amplify-text amplify-field__error-message">{validationErrors.acknowledgement}</p>
          </div> }
          </>
      }
    </>
  );
}

export default SignUpFormFields