import { useI18nContext } from '@/i18n/i18n-react'
import { useAuthenticator } from '@aws-amplify/ui-react'
import { useGetResourcesByUserQuery } from '@/store/api/ignion/user'
import { IconAdmin, IconSettingsMenu, IconDashboard, IconHelp, IconProjects, IconStock } from '@/components/common/IconButton/consts'
import AppSubMenu from './AppSubMenu'
import type { MenuModel } from '../types/types'


const AppMenu = () => {
  const { LL } = useI18nContext()
  const { user } = useAuthenticator(context => [context.user])
  const { data } = useGetResourcesByUserQuery({ username: user?.username ?? '' }, { skip: user?.username === undefined})

  const model: MenuModel[] = [];
  model.push({ label: LL.menu.main.dashboard(), icon: <IconDashboard />, iconActive: <IconDashboard selected />, to: '/dashboard' })
  model.push({ label: LL.menu.main.projects(), icon: <IconProjects />, iconActive: <IconProjects selected />, to: `/organization/${user?.attributes?.['custom:orgId']}/project`, disabled: !(data?.includes('GET:/organization/{org_id}/project'))  })
  model.push ({ label: LL.menu.main.buy(), icon: <IconStock />, iconActive: <IconStock selected />, to: '/stock' })
  model.push({ label: LL.menu.main.helpCenter(), icon: <IconHelp />, iconActive: <IconHelp selected />, to: '/help' })
  
  if (data?.includes('GET:/admin/user')) {
    model.push({ label: LL.menu.main.engineering(), icon: <IconSettingsMenu />, iconActive: <IconSettingsMenu selected />, to: '/engineering-tools' })
    model.push({ label: LL.menu.main.admin(), icon: <IconAdmin />, iconActive: <IconAdmin selected />, to: '/admin' })
  }

  return <AppSubMenu model={model} />;
};

export default AppMenu;


