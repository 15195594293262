import { FC } from 'react'
import { MainButton } from '@/components/common/MainButton'
import { useI18nContext } from '@/i18n/i18n-react'
import { styleMainButton } from '@/types/common'
import { InputTextarea } from 'primereact/inputtextarea'
import { IconButton } from '@/components/common/IconButton'
import { IconClose } from '@/components/common/IconButton/consts'
import { useNps } from './useNps'
import { NpsProps } from './types'
import { daysToAddNPSClose, daysToAddNPSLater } from './const'


const Nps: FC<NpsProps> = ({ hideModal }) => { 
    const { LL } = useI18nContext()

    const {
        state: { 
            puntuation,
            message
        },
        actions: { 
            renderVotes,
            setMessage,
            askMeLater,
            handleVote
        },
      } = useNps({ hideModal })

    return (
        <>
          <IconButton handleClick={() => {askMeLater(daysToAddNPSClose)}}><IconClose /></IconButton>
          <h4 className='Oxion-bodyLarge-sBold'>{LL?.message.nps.title() ?? 'How are we doing so far?'}</h4>
          <span className='Oxion-small-medium'>{LL?.message.nps.recommend() ?? 'Would you recommend Oxion to your peers?'}</span>
          <div className='npsVotation'>
            { renderVotes() }
          </div>
          <span className='Oxion-small-medium'>{LL?.message.nps.comments() ?? 'Comments'}</span>
          <InputTextarea rows={5} className='w-full' maxLength={500} value={message}
              onChange={(e) => setMessage(e.target.value)} placeholder={LL?.message.nps.placeholder() ?? 'We greatly value you taking a few seconds to give us feedback.'} />

          <div className="disclaimerOptions">
            <MainButton label={LL?.buttons.maybeLater() ?? 'Maybe later'} handleClick={() => {askMeLater(daysToAddNPSLater)}} style={styleMainButton.TERCIARY} />
            <MainButton label={LL?.buttons.submit() ?? 'Submit'} disabled={puntuation<0}  handleClick={handleVote} /> 
          </div>
        </>
    )
}


export default Nps