import React, { FC, RefObject } from 'react'
import { ProjectMenuOptions } from '@/types/project'
import clsx from 'clsx'
import { OverlayPanel } from 'primereact/overlaypanel'

export type ContextualMenuProps = {
    optionsMenuItems: ProjectMenuOptions[]
    reference: RefObject<OverlayPanel>
}

const ContextualMenu: FC<ContextualMenuProps> = ({ optionsMenuItems, reference }) => {
  
  return (
    <div className='contextualMenu'>
        {
        optionsMenuItems.map(option => <div key={option.label} className={clsx(`contextualMenu-Item ${option.extraClass ?? ''}`, option?.visible === false && 'hide')}><button onClick={(e) => { e.stopPropagation(); option.command(); reference.current?.toggle(e) }}>{option.icon} <span className='Oxion-body-regular'>{ option.label }</span></button></div>)
        }
    </div>
  );
};

export default ContextualMenu