import { useState } from 'react'
import { useUserInteraction } from '@/hooks/useUserInteraction'
import { RadioButtonIcon } from '@/components/common/RadioButtonIcon'
import { NpsHookOutput } from './types'

export const useNps = ({
    hideModal
}: {
    hideModal: () => void
}): NpsHookOutput => {

    const [puntuation, setPuntuation] = useState<number>(-1)
    const [message, setMessage] = useState<string>('')

    let askmeLaterNPS: (days: number) => void;
    let voteNPS: (puntuation: number, message: string) => void;

    if (typeof window === 'undefined' || !(window as any).__STORYBOOK_ADDONS_CHANNEL__) {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const { askmeLaterNPS: realAskmeLaterNPS, voteNPS: realVoteNPS } = useUserInteraction();
        askmeLaterNPS = realAskmeLaterNPS;
        voteNPS = realVoteNPS;
    } else {
        askmeLaterNPS = () => {};
        // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
        voteNPS = (puntuation: number, message: string) => {};
    }
    
    const askMeLater = (daystoAdd:number) => {
        askmeLaterNPS(daystoAdd)
        hideModal()
    }

    const handleVote = () => {
        voteNPS(puntuation, message)
        hideModal()
    }

    const renderVotes = () => {
        const components = [];
        for (let index = 0; index <= 10; index++) {
            components.push(<div key={`npsVote-${index}`} className="npsVote">
                <RadioButtonIcon active={puntuation === index} handleClick={() => { setPuntuation(index) }} />
                <span className='Oxion-body-sBold'>{index}</span>
            </div>)
        }

        return components;
    }

    return {
        state: {
            puntuation,
            message
        },
        actions: {
            renderVotes,
            setMessage,
            askMeLater,
            handleVote,
        },
    }
}