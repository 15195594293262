import { FC, useLayoutEffect } from 'react'
import { useI18nContext } from '@/i18n/i18n-react'
import { useAuthenticator } from '@aws-amplify/ui-react'
import LinkButton from '../common/LinkButton'

type DefaultFooterProps = {
  uniqueId?: string
  forceRedirectToSignIn?: boolean
}

const DefaultFooter: FC<DefaultFooterProps> = ({ uniqueId, forceRedirectToSignIn = false }) => {
  const { LL } = useI18nContext()
  const { toSignIn } = useAuthenticator();

  useLayoutEffect(() => {
    if (uniqueId !== '') {
      const formEntity = document.querySelector('.authenticator-form form') as HTMLElement
      if (formEntity) {
        formEntity.id = `form${uniqueId}`
      }

      const btForm = document.querySelector('.authenticator-form form button.amplify-button--primary') as HTMLElement
      if (btForm) {
        btForm.id = `bt${uniqueId}`
      }
    }
  }, []);

  return (
    <div className='flex Oxion-small-regular align-items-center sigin-footer'>
      <span>{LL.signup.singinLabel()}</span>
      {
        forceRedirectToSignIn 
          ? <a href="/signin">{LL.signup.signinLink()}</a>
          : <LinkButton label={LL.signup.signinLink()} handleClick={() => toSignIn()} arrow={false} />
      }
    </div>
  );
}

export default DefaultFooter