import { CSSProperties } from 'react'
import { arrowDirectionButton } from '@/types/common'
import clsx from 'clsx'

interface LinkButtonProps {
label?: string;
handleClick: Function;
icon?: string;
iconPosition?: 'left' | 'right';
arrow?: boolean;
arrowDirection?: arrowDirectionButton;
disabled?: boolean;
style?: CSSProperties;
}

export default function LinkButton({ label = 'Link', handleClick, arrow = false, arrowDirection, disabled = false, icon, iconPosition, style }: Readonly<LinkButtonProps>) {
    return (
        <button className={clsx('flex items-center button-link', disabled && 'disabled')} onClick={() => handleClick()} style={style}>
          {(iconPosition === 'left' || arrow && arrowDirection === arrowDirectionButton.BACK_ARROW) && <i className={clsx('Primary-Color-500', icon ?? 'ms ms-arrow-back')} /> }
          <span>{label}</span>
          {(iconPosition === 'right' || arrow && arrowDirection === arrowDirectionButton.FORWARD_ARROW) && <i className={clsx('Primary-Color-500', icon ?? 'ms ms-arrow-forward')} /> }
        </button>
      )
}