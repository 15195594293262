import type { BaseTranslation } from '../i18n-types'

const en = {
	landing: {
		createdBy: {
			tryNow: 'TRY OXION NOW',
			hero: {
				heading_1: 'From now on,',
				heading_2: 'you create,',
				heading_3: ' we ignite',
				heading_4: '.',
				intro: 'Introducing Oxion™: our AI-powered platform that revolutionizes antenna integration by enabling Virtual Antenna® customers to complete their designs in seconds.'
			},
			createdBy: 'Created by',
			about: {
				title: 'Removing barriers to IoT development drives us. That\'s why we\'ve made using Virtual Antenna® components simple with our powerful, user-friendly platform.',
				text: 'And this is just the beginning. By making our Virtual Antenna® technology universally accessible, we can unleash the full potential of brilliant ideas. Oxion is where the future of IoT takes shape.',
				ideasAlive: 'Your Ideas alive'
			},
			features: {
				title: 'FEATURES',
				available: 'AVAILABLE',
				designExplorer: {
					title: 'AI Design Explorer',
					text: 'Test the feasibility of your design in real-time, including antenna selection, location and estimated performance, for free. Oxion enables you to tweak your design performance with the easy to use drag and drop interface, powered by our AI model. You can adjust antenna position, clearance area and board dimensions to easily decide on the layout and performance that meet your requirements.'
				},
				dashboard: {
					title: 'Projects Dashboard',
					text: 'Keep all your IoT product designs in one place, including all your adjustments and versions. Compare project design results and collaborate with your colleagues by sharing your projects and maintaining a comprehensive record of all modifications.',
				},
				help: {
					title: 'Help Center and Support',
					text: 'Access Ignion\'s support resources and technical documentation on Virtual Antenna® technology that guide you through the antenna integration process.',
					text2: 'We are here to ensure a smooth and effortless antenna design experience.',
				},
				stock: {
					title: 'Stock Availability',
					text: 'Get direct access to Ignion’s distribution channels to easily order your chosen antenna design, including antenna and matching network components.',
				},
				build: {
					title: 'BUILT FOR ENGINEERS',
					text: 'Get to market faster',
					text2: 'with ',
					text3: 'no need',
					text4: ' for a',
					text5: 'physical prototype',
				}
			},
			facts: {
				intro: {
					intro1: 'Designing wireless',
					intro2: 'products ',
					intro3: 'has never',
					intro4: 'been easier.'
				},
				realTime: {
					title: 'Real-time performance tweaking.',
					text: 'With Oxion your idea comes to life in a digital twin, allowing you to make instant design tweaks. Experiment with antenna placement, clearance, and PCB size while receiving immediate performance feedback.'
				},
				rf: {
					title: 'Demystifying RF for all.',
					text: 'Whether you have little to no RF expertise or you are a seasoned veteran, Oxion demystifies antenna integration and allows you to work with confidence and autonomy by making the process transparent and uncomplicated.'
				},
				conceptToMarket: {
					title: 'Guided from concept to market.',
					text: 'Oxion will be your connectivity copilot through out the entire design process, from idea conception to production.'
				},
				innovators: {
					title: 'Created by antenna innovators.',
					text: 'Oxion benefits from the knowledge and experience of the Ignion team with more than 50 million wireless devices deployed across all market segments and all wireless standards.'
				}
			},
		},
		nextIdeas: 'Your next successful project begins here.',
		footer: {
			fromNow: 'From now on,',
			youCreate: 'you create, we ignite.',
		},
		toTop: 'Go to top'
	},
	signin: {
		header: 'Welcome',
		registerLabel: 'Don’t have an account?',
		registerLink: 'Sign up',
		usernameLabel: 'Email',
		usernamePlaceholder: 'e.g. john@company.com',
		passwordLabel: 'Password',
		passwordPlaceholder: 'Enter your password',
		forgotPasswordLink: 'Forgot password?',
		companyPlaceholder: 'e.g. Company name',
	},
	signup: {
		header: 'Create account',
		givenNameLabel: 'Name',
		givenNamePlaceholder: 'e.g. John',
		familyNameLabel: 'Last name',
		familyNamePlaceholder: 'e.g. Doe',
		usernameLabel: 'Email',
		usernamePlaceholder: 'e.g. john@company.com',
		usernameError: 'Invalid email',
		passwordLabel: 'Password',
		passwordPlaceholder: 'Enter your password',
		confirmPasswordLabel: 'Confirm password',
		confirmPasswordPlaceholder: 'Confirm your password',
		roleLabel: 'Role',
		rolePlaceholder: 'Select a role',
		termsSubject: 'I accept Ignion\'s',
		terms: 'Terms of Service',
		termsConcatenation: '&',
		privacyPolicy: 'Privacy Policy',
		consentDataError: 'You must consent your data processing',
		termsError: 'You must accept the terms and conditions',
		singinLabel: 'Already have an account?',
		signinLink: 'Sign in',
		companyLabel: 'Company',
		companyPlaceholder: 'e.g. Company name',
		countryLabel: 'Country',
		countryPlaceholder: 'e.g. Spain',
		invalidValue: 'Invalid value',
		termsPolicy: {
			title: 'Terms Of Service and Privacy information',
			dataController: 'Data controller: IGNION, S.L.',
			purpose: 'Purpose: manage your request and contact you to fulfil your specific request, enquiry or claim and if you give us your consent, send you relevant information about our products or our newsletter.',
			rights: 'Rights: you can exercise your rights of access, rectification, cancellation and opposition by contacting us at yourdata@ignion.io. More info:',
			agree: 'I agree to receive information about products or newsletter.'
		}
	},
	resetPassword: {
		header: 'Reset password',
		usernameLabel: 'Email',
		usernamePlaceholder: 'e.g. john@company.com',		
	},
	forceResetPassword: {
		passwordLabel: 'Password',
		passwordPlaceholder: 'Enter your password',
		confirmPasswordLabel: 'Confirm password',
		confirmPasswordPlaceholder: 'Confirm your password',
	},
	forceNewPassword: {
		passwordLabel: 'Password',
		passwordPlaceholder: 'Enter your password',
		confirmPasswordLabel: 'Confirm password',
		confirmPasswordPlaceholder: 'Confirm your password',
	},
	forgotPassword: {
		usernameLabel: 'Email',
		usernamePlaceholder: 'e.g. john@company.com',		
	},
	confirmResetPassword: {
		header: 'Reset password',
		intro: 'If there is an account linked to {email:string} you will receive an email with a confirmation code to reset your password.',
		codeLabel: 'Code',
		codePlaceholder: 'Enter your code',		
		passwordLabel: 'Password',
		passwordPlaceholder: 'Enter your password',
		confirmPasswordLabel: 'Confirm password',
		confirmPasswordPlaceholder: 'Confirm your password',		
		singinLabel: 'Already have an account?',
		signinLink: 'Sign in',		
	},
	confirmSignIn: {
		codeLabel: 'Code',
		codePlaceholder: 'Enter your code',		
	},
	buttons: {
		add: 'Add',
		save: 'Save',
		accept: 'Accept',
		done: 'Done',
		back: 'Back',
		next: 'Next',
		cancel: 'Cancel',
		discard: 'Discard',
		discardProgess: 'Discard progress',
		archive: 'Archive',
		upload: 'Upload',
		uploadFile: 'Upload file',
		uploadImage: 'Upload image',
		uploadPcb: 'Upload PCB',
		uploadDxf: 'Upload DXF',
		saveAndContinue: 'Save and continue',
		compare: 'Compare',
		saveAndClose: 'Save and close',
		getHelp: 'Get help',
		browseFiles: 'Browse files',
		yes: 'Yes',
		no: 'No',
		create: 'Create project',
		createDesign: 'Create design',
		editInfo: 'Edit info',
		nextStep: 'Next step',
		exit: 'Exit',
		submit: 'Submit',
		maybeLater: 'Maybe later',
		skip: 'Skip'
	},
	dynamic: {
		PROJECT:{
			application: {
				label: 'Application',
				placeholder: 'Select an option',
				ATL: 'Asset Tracking & Logistics',
				AT: 'Automotive Telematics',
				CE: 'Consumer Electronics',
				ER: 'Educational & Research',
				HC: 'Healthcare',
				IIOT: 'Industrial IoT',
				SC: 'Smart City',
				SHB: 'Smart Home & Buildings',
				SM: 'Smart Metering',
				WH: 'Wearables & Hearables',
				OTHER: 'Other'
			},
			name: {
				label: 'Project name',
				placeholder: 'e.g. My first project'
			},
			description: {
				label: 'Description (optional)',
				placeholder: 'A few hints about your project'
			},
			project_owner: 'Project owner',
			stage: {
				label: 'Status',
				PRF: 'PoC',
				DSG: 'Design review',
				QLF: 'Ready',
				CSD: 'Consider',
				AWR: 'Awareness'				
			},
			username: 'Username',
			estimated_anual_units: {
				label: 'Estimated annual units',
				placeholder: 'Select an option',
				EAU10: '<10K',
				EAU25: '10K-25K',
				EAU50: '25K-50K',
				EAU100: '50K-100K',
				EAU300: '100K-300K',
				EAU1M: '300K-1M',
				EAUXM: '>1M'
			},
			distributor_name: {
				label: 'Preferred distributor',
				placeholder: 'Select an option',
				NINETYONEChip: '91Chip',
				AITG: 'AITG',
				Bately: 'Bately',
				Honestar: 'Honestar',
				Huachip: 'Huachip',
				Realcom: 'Realcom',
				Richardson: 'Richardson RFPD',
				Sekorm: 'Sekorm',
				WillasArray: 'WillasArray',
				Zhenyuele: 'Zhenyuele'
			},
			ignion_representative: {
				label: 'Ignion representative  (optional)',
				placeholder: 'Select an option',
				'jeff.chen@ignion.io': 'Jeff Chen',
				'andy.niu@ignion.io': 'Andy Niu',
				'bruce.cai@ignion.io': 'Bruce Cai',
				'crystal.zhou@ignion.io': 'Crystal Zhou'
			},
			contacts: 'Contacts',
			open_date: 'Open date',
			closing_date: 'Closing date',
			production_date: 'Planned production date',
			aic_origin: {
				label: 'AIC Origin',
				NN_Client: 'Client',
				NN_Sales: 'Sales',
				NN_Marketing: 'Marketing',
				NN_Support: 'Support',
				NN_Other: 'Other'
			}
		},
		antennas: {
			wireless_service: {
				label: 'Wireless Service',
				placeholder: 'Select an option',
				BLT: 'Bluetooth',
				GNSS: 'GNSS',
				D2020: 'DECT-2020 NR',
				LORA: 'LoRaWAN',
				LTE: 'LTE',
				LTEM: 'LTE-M',
				NB: 'NB-IoT',
				SWLK: 'Sidewalk',
				SFOX: 'Sigfox',
				THR: 'Thread',
				UWB: 'UWB',
				WIFI: 'Wi-Fi',
				W6E7: 'Wi-Fi 6E/7',
				WSUN: 'WI-SUN',
				ZBEE: 'ZigBee',
				ZWV: 'Z-Wave',
				GEN23: '2G - 3G',
				GEN5: '5G NR FR1',
				OTHER: 'Other',			
			},
			preferred_ant: {
				label: 'Preferred Antenna',
				placeholder: 'Select an option',
				'': 'Choose for me',
				'NN02-101': 'NANO mXTEND™',
				'NN02-201': 'ONE mXTEND™',
				'NN03-320': 'DUO mXTEND™',
				'NN03-310': 'TRIO mXTEND™',
				'NN02-220': 'ALL mXTEND™',
				'NN02-224': 'RUN mXTEND™',
			},
			rf_module: {
				label: 'RF module',
				placeholder: 'Select an option',
				CVW: 'Cavli Wireless',
				CTM: 'Custom Module',
				FBC: 'Fibocom',
				NDS: 'Nordic Semiconductor',
				QTL: 'Quectel',
				SQN: 'Sequans',
				SMT: 'Semtech',
				SRW: 'Sierra Wireless',
				SLL: 'Silicon Labs',
				SMC: 'SIMCom',
				STM: 'STMicro',
				TLT: 'Telit',
				TXI: 'Texas Instruments',
				THL: 'Thales',
				UBX: 'Ublox',
				OTHER: 'Other'
			},
			freq_ranges: {
				label: 'Frequency Ranges',
				labelShort: 'Freq. Ranges',
				placeholder: 'Select one or various options',
				OTHER: 'Other',
				RANGE: 'Range',
				generic: 'Freq. Ranges (MHz)',
				low: 'Low Freq. Ranges (MHz)',
				mid: 'Mid Freq. Ranges (MHz)',
				high: 'High Freq. Ranges (MHz)',
			},
			add_antenna: 'Add antenna',
			add_an_antenna: 'Add an antenna',
			commStandard: 'Comm. Standard',
			xfeed: 'Feeding line',
			size: 'Antenna size'
		},
		NNS10: {
			name: 'Design name',
			antennas: 'Antennas',
			pcb_shape: {
				label: 'PCB Shape',
				RECT: 'Rectangle',
				CIRC: 'Circle',
				OTHER: 'Other'
			},
			pcb_length: 'PCB Length',
			pcb_width: 'PCB Width',
			pcb_height: 'PCB Height',
			pcb_radius: 'PCB Radius (min: 12mm; max: 150mm)',
			pcb_radius_error: 'Invalid number: 150mm max; 12mm min',
			pcb_square: 'PCB length & width (min: 12mm; max: 300mm)',
			pcb_square_error: 'Invalid number: 300mm max; 12mm min',
		}
	},
	wizard: {
		title: {
			header: 'Create a project',
			headerService: 'Create a design',
			details: 'Project information and details',
			step: [
				{
					a: 'Let\'s get started: explain us all ',
					b: 'your project details'
				},
				{
					a: 'Tell us more about your ',
					b: 'project specifications'
				},
				{
					a: 'Tell us more about your ',
					b: 'project specifications'
				},
				{
					a: '',
					b: ''
				}
			],					
		},
		legend: {
			step1: 'Project details',
			step2: 'Design details',
			step3: 'Antennas'
		},
		discard: {
			title: 'Save changes',
			desc: 'You have unsaved progress. Do you want to save and close the current project creation?'
		},
		errors: {
			realTime: 'Our AI-powered real-time estimations are currently optimized for single-antenna scenarios. Don\'t worry! You can still add up to four antennas, and our engine will process your request promptly.'
		}
	},
	menu: {
		main: {
			dashboard: 'Dashboard',
			projects: 'Projects',
			buy: 'Stock',
			helpCenter: 'Help center',
			admin: 'Admin',
			engineering: 'Eng. tools',
			logOut: 'Log Out'
		},
		top: {
			markAllAsRead: 'Mark all as read',			
			accountSettings: 'Account settings',
			messages: 'Messages',
			logOut: 'Log out',
			noNotifications: 'There is no unread notifications'
		}
	},
	dashboard: {
		welcome: 'Welcome, ',
		welcomeBack: 'Welcome back, ',
		card: {
			title: 'Explore the power of AI/ML',
			content: 'Get your digital twin'
		},
		button: {
			newProject: 'New project'
		},
		recent: 'Last modified projects',
		viewAll: 'View all',
		recommended: 'Recommended for you',
		guides: 'Design Guides',
		webinars: 'Webinars',
		blogs: 'Blog',
		empty: {
			hello: 'Hello,',
			getStarted: 'Let\'s get started',
			subtitle: 'Explore the power of AI/ML.',
			content: 'Get your digital twin.',
			newProject: 'New project',
			ideasAlive: 'Your ideas. Alive.'		
		},
		banners: {
			moocs: {
				title: {
					preHilight: 'Introducing: ',
					hilight: 'Virtual Antenna®',
					postHilight: ' Academy'
				},
				description: 'Explore the fundamentals of Ignion\'s groundbreaking technology with this open online course.',
				button: 'Learn more'
			}
		}
	},
	account: {
		title: 'Settings',
		description: 'Manage here your details',
		menu: {
			details: 'Account details',
			password: 'Password',
			notifications: 'Email notifications',
			billing: 'Billing'
		},
		details: {
			avatar: {
				update: 'Upload profile image',
				remove: 'Remove'
			},
			givenName: {
				label: 'First name',
				tooltip: 'First name'
			},
			familyName: {
				label: 'Last name',
				tooltip: 'Last name'
			},
			email: {
				label: 'Email',
				tooltip: 'Email'
			},
			phoneNumber: {
				label: 'Phone number',
				tooltip: 'Phone number',
				placeholder: 'e.g. +34 123 456 789'
			},
			company: {
				label: 'Company',
				tooltip: 'Company'
			},
			country: {
				label: 'Country',
				tooltip: 'Country'
			},
			role: {
				label: 'Role',
				tooltip: 'Role'
			},
			language: {
				label: 'Language',
				tooltip: 'Language'
			},
			save: 'Save changes'
		},
		password: {
			title: 'Password',
			subtitle: 'Your password must be at least 8 characters and cannot contain spaces or match your email address.',
			oldPassword: {
				label: 'Old password',
				tooltip: 'Old password'
			},
			newPassword1: {
				label: 'New password 1',
				tooltip: 'New password 1',
				help: ''
			},
			newPassword2: {
				label: 'New password 2',
				tooltip: 'New password 2',
				help: ''
			},
			save: 'Update password'
		},
		notifications: {
			title: 'Email notifications',
			projectActivity: 'Project activity',
			optionNewMembers: 'Email me if new members are added to the project.',
			optionProjectUpdate: 'Email me when there are updates or changes to a project.',
			completed: 'Email me when the performance validation is completed.',
			messaging: 'Messaging',
			optionNewMessages: 'Email me when I receive new messages',
			aicannuncements: 'AIC Announcements',
			optionTips: 'Tips and practical information on AIC usage',
			optionProductsUpdates: 'Product and feature updates',
			optionEvents: 'Events and promotions',
			optionSurveys: 'Surveys and product testing',
			info1: 'Receive articles, case studies, videos, webinars, tips from other users, and best practices to make the most out of the tool.',
			info2: 'Get information about early access to beta versions, new product launches, and improvements to existing features.',
			info3: 'Receive notifications about events, special offers, and promotions.',
			info4: 'Receive invitations to participate in surveys, user experience research, and beta testing of products.'
		}

	},
	project: {
		share: {
			title: 'Share project',
			description: 'Give your teammates access to this project and start collaborating.',
			search: 'Email',
			add: 'Add',
		},
		empty: {
			title: 'No designs yet',
			description: 'Create a new design to explore the power of the Virtual Antenna® technology for your next product.'
		},
		new: {
			name: 'Project name',
			descriptionTitle: 'Share all the details about your project.',
			description: 'Add a brief project description so that any member of your team always has the necessary details.',
			eau: 'Estimated annual units',
		},
		list: {
			header: 'Your projects',
			subheader: 'Projects by {company:string}.',
			button: {
				newProject: 'New project'
			},
			empty: 'No projects match your criteria.'
		},
		details: {
			projectPanel: 'Project panel',
			title: 'Performance results',
			lastModified: 'Last modified',
			created: 'Created',
			designRequirements: 'Design requirements',
			select: {
				request: 'Design version'
			},
			recommended: {
				antennas: {
					title: 'Antennas in your design',
					description: 'Select an antenna to view its performance:'
				}
			},
			button: {
				newRequest: 'New design',
				verify: 'Verify',
				compare: 'Compare performance',
				antennaConfigurator: 'AI Design Explorer',
				downloads: 'Downloads',
				request: 'Request'
			},
			download: {
				report: 'PDF report',
				dxf: 'DXF design files',
				datasheet: '{antenna:string} datasheet'
			},
			noServices: {
				title: 'No designs',
				description: 'There are no designs for this project.'
			},
			design: {
				recommendation: {
					title: 'Design Recommendations',
					description: 'For performance optimization with Virtual Antenna® technology'
				}
			},
			clearance: 'Clearance',
			matching: 'Matching',
			pcb: 'PCB Dimensions',
			radius: 'Radius',
			pcbRadius: 'PCB Radius',
			selectAntenna: 'Select an antenna to view its recommended placement on the PCB, the matching network, and the expected device performance.',
			pcbLength: 'Length',
			pcbWidth: 'Width',
			status: {
				label: 'Project status',
				proof: 'PoC analysis',
				design: 'PCB design review',
				qualification: 'Prototyping',
				complete: 'Ready'
			},
			pcbLegend: {
				filter: 'Filter',
				mnetwork: 'Matching network',
				clearance: 'Clearance Area',
			},
			pending: {
				title: 'Get your digital prototype',
				description: 'Receive your tailored layout based on your PCB requirements including the Virtual Antenna® component placement and recommended clearance area.'
			},
			moretime: {
				title: 'Your request will take us some extra time',
				description: ' Take a coffee break and we will notify you when it’s ready by email or through your notifications.',
			},
			error: {
				title: 'Whoa, your request is a bit unique!',
				description: 'Our team of experts is now analyzing your request to find a feasible solution. We will contact you shortly with the result of our analysis. In the meantime, you can contact us or explore a new design for your product.',
				contact: 'Contact us',
				newDesign: 'Create a new design'
			},
			notFeasible: {
				title: 'You are pushing the boundaries!',
				description: 'Unfortunately, we cannot meet your requirements to integrate Virtual Antenna® technology in your design. We would like to invite you to create a new design version based on our experts’ recommendations:',
				recommendations: 'Design recommendations'
			},
			generatingPCB: 'Generating PCB image',
			suggestedVersion: {
				title: 'Our suggested version',
				description: 'Your request is great, but we might have a more suitable Virtual Antenna® component for your use case. You can find our suggested design in the “Design version” dropdown menu.',
				button: 'Got it'
			},
			designVersion: {
				title: 'Create a new design version',
				description: 'Evaluating different design alternatives? Iterate through as many design versions as necessary and compare them to find the optimal solution for your device.',
				button: 'Got it'
			}
		},
		info: {
			title: '{project:string} details',
			edit: 'Edit project info',
			description: 'Description (optional)',
			descriptionForm: 'Description',
			date: 'Planned production date',
			pcbDimmensions: 'PCB dimensions',
			nda: 'NDA',
			ndaLink: 'nda_link',
			application: 'Application',
			eau: 'Estimated annual units',
			promotedSim: 'Promoted design',
			support: 'Ignion support',
			noSupport: 'General support',
			fileDescription: 'grb. file less than 2MB',
			uploadPcb: 'Drag and drop your Gerber file here or browse files to upload',
			eauValues:	{
				EAU10: '<10K',
				EAU25: '10K-25K',
				EAU50: '50K-100K',
				EAU100: '50K-100K',
				EAU300: '100K-300K',
				EAU1M: '300K-1M',
				EAUXM: '>1M'
			},			
		},
		filter: {
			title: 'Filter By',
			button: {
				label: 'Filter',
				clearFilters: 'Clear filters',
				showResults: 'Show results'
			},
			stage: {
				label: 'Stage',
				all: 'All projects',
				PRF: 'Proof',
				DSG: 'Design',
				QLF: 'Qualification',
				CSD: 'Consider',
				AWR: 'Awareness'
			},
			virtualAntennaProducts: {
				label: 'Virtual Antenna ® products'
			},
			wirelessService: {
				label: 'Wireless service'
			},
			creationDate: {
				label: 'Creation date'
			},
			estimatedAnnualUnits: {
				label: 'Estimated annual units',
				EAU10: '<10K',
				EAU25: '10K-25K',
				EAU50: '25K-50K',
				EAU100: '50K-100K',
				EAU300: '100K-300K',
				EAU1M: '300K-1M',
				EAUXM: '>1M'
			},
			viewAll: 'View all',
			hide: 'Hide'
		},
		sort: {
			button: {
				label: 'Sort by'
			},
			field: {
				update: 'Update date',
				submit: 'Submit date'
			},
			order: {
				ascending: 'Ascending',
				descending: 'Descending'
			}
		},
		menu: {
			options: {
				details: 'Details',
				archive: 'Hide',
				edit: 'Edit',
				activate: 'Unhide',
				delete: 'Delete project'
			}
		},
		stage: {
			PRF: 'Proof',
			DSG: 'Design',
			QLF: 'Qualification',
			CSD: 'Consider',
			AWR: 'Awareness'
		},
		compare: {
			title: 'Compare design performance',
			version: {
				title: 'Select version',
				description: 'Pick the design performance version for comparison.'
			},
			efficiency: 'Efficiency',
			selector: {
				label: 'Select design',
				placeholder: 'Choose design',
				antenna1: 'Comm. Std #1',
				antenna2: 'Comm. Std #2',
				antenna3: 'Comm. Std #3',
				antenna4: 'Comm. Std #4',
				antennaPlaceholder: 'Choose an option'
			},
			addRequest: 'Add design to compare',
			hideSection: 'Add more designs to enhance this feature'
		},
		verify: {
			title: 'Next step for your design',
			header: 'We are here to help you',
			introsteps: 'At Ignion, we provide the best Engineering Services to boost your design:',
			steps: [
				'· PCB design review.',
				'· 3D device analysis.',
				'· Matching Network design.',
				'· Consultancy.',
				'· Pre-certification.'
			],
			final: {
				highlighted: 'Select the design version',
				text1: 'you would like to base your device on and upload a PCB design file (if any).',
				text2: 'We will suggest the most suitable service for you design.'
			},
			version2: {
				congrats: 'Congrats! You are doing great with your design.',
				designOverview: 'Design Overview',
				intro: 'This is the design you have selected.',
				intro2: 'Not the right one? Select it from the dropdown:',
				download: 'Design files'
			}
		},
		optimize: {
			title: 'AI Design Explorer',
			save: {
				header: 'New design',
				button: 'Save design',
				description: 'Specify the name for the new design based on the current settings of the performance optimizer'
			},
			reset: {
				button: 'Reset',
				title: 'Unsaved changes',
				question: 'Are you sure you want to reset the configurator to the initial design?',
			},
			exitWarning: 'Are you sure you want to leave? Your changes will be discarded.',
			errors : {
				maxSizePCB: 'PCB Size should be between {min:string}mm and {max:string}mm', 
				pcbLengthShorter: 'PCB Length cannot be smaller than PCB Width',
				clearanceAntenna: 'Clearance Antenna should be larger than antenna size plus {distance:string}mm',
				clearancePCB: 'The clearance area cannot exceed the PCB area',
				clearanceSeparationPCB: 'The clearance width must either be equal to the PCB width or less, with a minimum separation of {distance:string}mm between them',
				IPT01: 'Oops, it seems that our Antenna Configurator cannot properly handle the PCB data. If the issue persists, please contact us through our Help Center',
				IPT02: 'Oops, it seems that our Antenna Configurator cannot properly handle the antenna data. If the issue persists, please contact us through our Help Center',
				PCB01: 'Apologies, but our Antenna Configurator only supports rectangular PCBs',
				PCB02: 'The PCB length must be greater than or equal to the PCB width',
				PCB03: 'The PCB length must be a value between 10 and 300, inclusive',
				PCB04: 'The PCB width must be a value between 10 and 300, inclusive',
				ANT01: 'Sorry, our Antenna Configurator only supports a single antenna',
				ANT02: 'Please strictly use one of our oficial antennas',
				ANT03: 'Please strictly use one of the wireless services we recommend',
				ANT04: 'Please strictly use one or more of the frequency ranges we recommend',
				ANT05: 'Please strictly use a combination of the wireless services and frequency ranges we recommend',
				MNT01: 'Sorry, there is not a suitable Matching Network for this configuration',
				CLW01: 'The clearance width cannot exceed the PCB width',
				CLW02: 'The clearance width must either be equal to the PCB width or less, with a minimum separation of 5 mm between them',
				CLW03: 'The clearance width is less than the minimum allowed',
				CLW04: 'The clearance width exceeds the maximum allowed',
				CLL01: 'The clearance length cannot exceed the PCB length',
				CLL02: 'The clearance Length is less than the minimum allowed',
				CLL03: 'The clearance length exceeds the maximum allowed',
				XFD01: 'The feeding line is less than the minimum allowed',
				XFD02: 'The feeding line exceeds the maximum allowed',
				feedingLineMin: 'The feeding line could not be lower than 0',
				feedingLineMax: 'The maximum feeding line available for antenna {antenna:string} is {max:string}mm',
				PCBWIDTHSMALLERXFEED: 'PCB Width cannot be smaller than antenna size.',
				PCBWIDTHSMALLERCLEARANCE: 'PCB Width cannot be smaller than clearance width'
			}
		},
		delete: {
			title: 'Delete project',
			confirmMessage: 'Are you sure you want to delete this project?',
		},
		gerberReview: {
			title: 'Upload your Gerber file',
			description: 'Please, try to work on our recomended .dfx file for your design.',
			questionary: {
				windowTitle: 'Help us to understand your file',
				steps: {
					step1: 'Layers',
					step2: 'Orientation'
				},
				q1: {
					title: 'What is your top signal layer name? *',
					desc: 'This indicates all copper areas (pads, traces, etc) on top layer. Please tell us the name with extension of it in your project.',
					label: 'Top signal layer name',
					placeholder: 'e.g. PCB_Copper_Signal_Top.gbr'
				},
				q2: {
					title: 'Does your design have bottom layer?',
					desc: 'This indicates all copper areas (pads, traces, etc) on bottom layer. Please tell us the name with extension of it in your project.',
					label: 'Bottom signal layer name',
					placeholder: 'e.g. PCB_Copper_Signal_Bot.gbr'
				},
				q3: {
					title: 'Where is your antenna footprint layer? *',
					desc: '',
					opt1: 'Top',
					opt2: 'Bottom'
				},
				q4: {
					title: 'Where are your matching network pads layer? *',
					desc: '',
					opt1: 'Top',
					opt2: 'Bottom'
				},
				q5: {
					title: 'Please, indicate the name of any intermediate layer where there is copper',
					desc: '',
					label: 'Layer name',
					placeholder: 'e.g. PCB_Copper_Signal_Bot.gbr'
				},
				q6: {
					title: 'FO you have a border layer to indicate clearly the PCB area limits?',
					desc: '',
					opt1: 'Yes',
					opt2: 'No'
				},
				q7: {
					title: 'Please indicate your PCB orientation *',
					desc: '',
					opt1: 'Vertical',
					opt2: 'Horizontal'
				},
				q8: {
					title: 'Please, indicate the antenna position approximately where we must look for *',
					desc: '',
					opt1: 'Top-Left',
					opt2: 'Top-Right',
					opt3: 'Bottom-Right',
					opt4: 'Bottom-Left'
				}
			}
		}
	},
	report: {
		new: {
			name: 'Estimation name',
			setupPcb: 'Set up your PCB details',
		},		
		error: {
			file: {
				notFound: 'File not found',
				download: 'Error downloading file'
			}
		}
	},
	antenna: {
		'NN02-101': 'NANO mXTEND™',
		'NN02-201': 'ONE mXTEND™',
		'NN03-320': 'DUO mXTEND™',
		'NN03-310': 'TRIO mXTEND™',
		'NN02-220': 'ALL',
		'NN02-224': 'RUN mXTEND™',
		details: {
			dimmensions: 'Dimensions',
			frequencyRanges: 'Frequency range(s)',
			datasheet: 'DOWNLOAD DATASHEET',
			overallPerformance: {
				title: 'Antenna performance',
				description: 'Your design overall performance is shown below.'
			},
			matchingNetwork: {
				title: 'Matching network',
				description: 'matching network topology',
				details: {
					title: 'Matching network',
					table: {
						component: 'Component',
						value: 'Value',
						partNumber: 'Part Number',
						manufacturer: 'Manufacturer'
					}
				}
			}
		},
		performance: {
			details: {
				title: 'Antenna performance',
				subtitle: 'Expected device performance with antenna 1',
				description: 'Your prototype using the {antenna:string} antenna is expected to achieve the reflection coefficient (in dB) and total efficiency (in %) as shown in this graph. Rule of thumb: it is desirable to have a reflection coefficient below -6 dB, ensuring proper impedance matching of the antenna component and optimized total efficiency.',
				increase: 'INCREASE YOUR PERFORMANCE: to increase the performance we recommend evaluating your PCB again with increased dimensions (increasing length by 10 mm typically results in a total efficiency improvement of 0.5 dB).',
				table: {
					header: '{partNumber:string} for {commStd:string}',
					frequency: 'Frequency (GHz)',
					efficiency: 'Total Efficiency (%)',
					s11: 'S11 (dB)',
					originalEfficiency: 'Original Efficiency (%)',
					optimizedEfficiency: 'New Efficiency (%)',
					originals11: 'Original S11 (dB)',
					optimizeds11: 'New S11 (dB)'
				}
			}
		},
		button: {
			moreDetails: 'View more details',
		},
		suggested: 'Suggested',
	},
	admin: {
		list: {
			header: 'Admin.',
			subheader: 'Manage all platform users and their permissions',
			tableHeader:{
				username: 'User name',
				company: 'Company',
				role: 'Role',
				lastActive: 'Last active',
				projects: 'Projects'
			},
			table: {
				empty: 'No users found'
			},
			filter:{
				filterBy: 'Filter By',
				company: 'Company Name',
				companyPlaceholder: 'Select a company',
				role: 'Role Name',
				clear: 'Clear Filters',
				results: 'Show Results'
			},
			user:{
				email: 'Email',
				company: 'Company',
				phone: 'Phone',
				country: 'Country',
				contributions: 'Project Contributions'
			}
		},
		resetTokens: {
			title: 'Reset tokens',
			confirmMessage: 'Are you sure you want to reset all the user tokens?'
		}
	},
	error: {
		invalidRange: 'Invalid range',
		invalidEmail: 'Invalid email',
		invalidPhone: 'Invalid phone number',
		invalidCountry: 'Invalid country',
		invalidCompany: 'Invalid company',
		invalidRole: 'Invalid role',
		invalidUsername: 'Invalid username',
		invalidPassword: 'Invalid password',
		invalidPasswordMatch: 'Passwords do not match',
		invalidPasswordStrength: 'Password must contain at least 8 characters, including uppercase, lowercase, number and special character',
		required: 'Required',
		requiredAll: 'All fields are required',
		invalid: 'Invalid',
		invalidFormat: 'Invalid format',
		invalidLength: 'Invalid length',
		invalidNumber: 'Invalid number',
		match: 'Do not match',
		invalidPhoneNumber: 'Invalid phone number',
		unavailableUseCase:  'Not available for your use case',
	},
	messages: {
		TOO_MANY_REQUESTS: {
			summary: 'Too many requests',
			detail: 'Please try again later'
		},
		UPLOAD_FILE_ERROR: {
			summary: 'File upload error',
			detail: 'File upload error'
		},
		GENERIC_ERROR: {
			summary: 'Something went wrong',
			detail: 'Please try again later'
		},
		GENERIC_ERROR_CUSTOM_MESSAGE: {
			summary: 'Something went wrong',
		},
		INVALID_ACCESS: {
			summary: 'Insufficient permissions',
			detail: 'Please contact the admin'
		},
		ADD_SERVICE_SUCCESS: {
			summary: 'Design added',
			detail: 'Design added successfully'
		},
		PROMOTE_SERVICE_SUCCESS: {
			summary: 'Design promoted',
			detail: 'Design promoted successfully'
		},
		ADD_PROJECT_SUCCESS: {
			summary: 'Project added',
			detail: 'Project added successfully'
		},
		UPDATE_PROJECT_SUCCESS: {
			summary: 'Project updated',
			detail: 'Project updated successfully'
		},
		DELETE_PROJECT_SUCCESS: {
			summary: 'Project deleted',
			detail: 'Project deleted successfully'
		},
		SEND_MAIL_SUCCESS: {
			summary: 'Email sent',
			detail: 'Email sent successfully'
		},
		UPDATE_USER_SUCCESS: {
			summary: 'User updated',
			detail: 'User updated successfully'
		},
		RELEVANT_INFORMATION: {
			summary: 'Information for Design specifications',
		},
		USEFUL_INFORMATION: {
			summary: 'Useful Information',
		},
		WELCOME_OPTIMIZER: {
			summary: 'The PCB is interactive!',
			detail: 'Drag and drop the different elements to explore the power of ML.'
		},
		PUSHING_LIMITS: {
			summary: 'Hey! You are pushing the limits!',
		},
		DESIGNEXPLORER_RESTRICTIONS: {
			summary: 'Something went wrong',
			detail: 'The change you are looking for cannot be implemented due to design restrictions. Please explore a different change in your design'
		},
		NO_MATERIAL: {
			summary: 'No material found',
		},
		NOT_FOUND: {
			summary: 'Resource not found',
			detail: 'The requested resource was not found'
		},
		THANKS_FEEDBACK: {
			summary: 'Thanks for your feedback',
		},
		UPLOAD_GERBER_FILE_AUTOMATIC: {
			summary: 'Gerber file uploaded',
			detail: 'We will need some information to process your file'
		},
		UPLOAD_GERBER_FILE_ENGINEERING: {
			summary: 'Gerber file uploaded',
			detail: 'An engineer will check your file soon'
		},
		QUESTIONARY_GERBER_FILE: {
			summary: 'Quiz completed succesfully',
			detail: 'The automatic gerber review will start soon'
		},
		ENGINEERING_SUCCESS: {
			summary: 'Engineering request',
			detail: 'The engineering request was successfully'
		}
	},
	help: {
		header: 'Help Center',
		ignionSupport: 'Ignion Support',
		subheader: 'Your resource for answers and support',
		res:{
			firsta: 'Let’s',
			firstb: 'get started',
			second: 'Application notes',
			third: 'FAQs',
			fourth: 'Knowledge hub',
			fifth: 'Events',
			sixth: 'Virtual Antenna®',
			moocs: 'Virtual Antenna® Academy',
		},
		desc:{
			first:'Your journey begins here',
			second:'The latest application notes',
			third:'Answers to common questions',
			fourth:'Dive into a world of information',
			fifth:'Stay Up-to-Date with Upcoming Happenings',
			sixth: 'Discover our range of antenna components',
			moocs: 'Explore the fundamentals of Ignion\'s groundbreaking technology with this open online course.'
		},
		here: 'We\'re here to help',
		subhere:'If you have any questions, concerns, or feedback, feel free to reach out directly. Our dedicated team is here to assist you every step of the way.',
		schedule: 'Do you have questions that require a more detailed discussion? Schedule a meeting with our team.',
		schedulelink: 'Book a meeting now',
		form:{
			project: 'Project',
			subject: 'Subject',
			message: 'Your Message',
			send: 'Send message',
			generalRequest: 'General request',
			errorNoProjects: 'No projects found'
		},
		getstarted:  {
			title: 'Let\'s get started',
			subtitle: 'First steps with the essentials',
			first: 'Navigating the dashboard.',
			second: 'Account Settings.',
			third: 'How to create a project.',
			fourth: 'Results view.',
			fifth: 'AI Design Explorer',
			sixth: 'Stock',
			desc: {
				first: '',
				second: '',
				third: '',
				fourth: '',
				fifth: '',
				sixth: '',
			}	
		},
		articles: {
			MOOCS: {
				title: 'Virtual Antenna® Academy',
				description: 'Explore the fundamentals of Ignion\'s groundbreaking technology with this open online course.',
				content: [
					'Dive into the core principles of RF with Dr. Jaume Anguera and a selected team of connectivity experts in this comprehensive MOOC.',
					'The course covers essential concepts in wireless technology, RF, and antenna performance.',
					'Discover Virtual Antenna® technology and understand how this cutting-edge solution addresses the main challenges in antenna integration and IoT connectivity, with applications in smart metering, tracking devices, wearables, and more.',
					'Relevant for:',
					'Students, engineers, researchers in electronics, wireless communication, RF, IoT device designers, field application engineers, and other wireless connectivity professionals.',
					'Enroll now'
				]
			}
		},
		more: {
			title: 'You may also want to read'
		},
		touch:{
			text1: 'Get in touch with us',
			text2: 'Have questions or need assistance? We\'re here to help.'
		}
	},
	stock : {
		title: 'Stock availability',
		subtitle: 'Check the stock availability of the components in your bill of materials.',
		search: {
			byproject: 'Search by your projects',
			bypartnumber: 'Search by part number directly',
			byor: 'or',
			selectProject: 'Select a project',
			selectDesign: 'Select a design version',
		},
		partnumber: 'Part number',
		materialList: 'Material list',
		product: 'Product',
		inStock: 'In stock',
		outStock: 'Out of stock',
		unitsNeeded: 'units needed',
		manufacturer: 'Manufacturer',
		image: 'Distributor',
		distributor: 'Name',
		price: 'Pricing',
		priceNote: 'Price for {units:string} units order volume. Please explore more pricing options or contact Ignion for inquiries in higher volumes',
		stock: 'Availability',
		moq: 'MoQ',
		packaging: 'Packaging',
		buyButton: 'Explore Pricing',
		messages: {
			search: 'Search to see results',
			loading: 'Loading...',
			noResults: '0 results. No data available.',
			errorRetrieving: 'There is no material matching your search'
		} 
	},
	message: {
		notification: {
			welcome: 'Welcome to the Oxion! Here you will find the updates of your projects.',
			serviceDone: {
				msg: 'The results of your design are available. ',
				link: 'Click to access the details.'
			},
			serviceDeny: 'We need more time for your request. An Ignion representative will contact you.',
			projectUpdated: 'has updated the project',
			projectShared: 'invited you to participate in the project ',
			error: {
				msg: 'Your request is a bit unique! We are on it.',
				link: 'Click to access the details.'
			},
			notFeasible: {
				msg: 'Unfortunately, we don\'t have a solution for your request.',
				link: 'Click to access the details and see design recommendations.'
			},
			releaseNotes: {
				name: 'Oxion {version:string}',
				description: 'is here. Check out our new updates'
			}
		},
		nps: {
			title: 'Tell us how we are doing!',
			recommend: 'How likely are you to recommend Oxion™ to your peers?',
			comments: 'Comments',
			placeholder: 'We greatly value you taking a few seconds to give us feedback.',
		}
	},
	language: {
		en: 'English',
		es: 'Spanish'
	},
	disclaimer: {
		line1: 'Oxion',
		line2: 'needs',
		line3: 'some',
		line4: 'space!',
		visitus: 'Please visit us in your desktop device.'
	},
	page404: {
		registered: {
			title: 'Oops!',
			description: 'We can\'t seem to find the page you\'re looking for. Maybe it\'s hiding? Looking for something in particular?',
			button1: 'Visit the Help Center',
			button2: 'Head to the dashboard'
		},
		notRegistered: {
			title: 'Oops!',
			description: 'We cannot find the page you are looking for. Explore the full range of functionalities and benefits by visiting us at',
			button: 'Go to oxion.ai'
		}
	},
	engineering: {
		tools: 'Engineering tools',
		description: 'Internal solutions for engineers',
		NewSimulation: {
			title: 'New Engineering Simulation',
			submit: 'Submit report',
			email: 'Your email',
			recommendedAntennas: 'Recommended Antenna',
			selectedAntennas: 'Selected Antenna',
			antenna: {
				label: 'Antenna',
				location: 'Location',
				position: 'Position'
			},
			newAntenna: {
				label: 'New Antenna',
				selectLocation: 'Select a location',
				selectPosition: 'Select a position',
				virtualAntenna: 'Virtual Antenna component',
				selectAntenna: 'Select an antenna',
				topology: 'MN topology',
				selectTopology: 'Select a topology',
				customTopology: 'Custom Topology',
				clearanceDimensions: 'Clearance Area Dimensions',
				antennaFilter: 'Antenna filter',
				selectFilter: 'Select a filter',
				customFilter: 'Custom Filter',
				selectService: 'Select a service',
				freqGuide: 'f.e. 698-960,1710-2690,3400-3800'
			}
		},
		replaceCustomerError: {
			title: 'Replace Customer Error',
			originalFormID: 'Form ID with error (original customer request)',
			newFormID: 'Form ID of the new report (it will replace the original)',
			deleteOriginal: 'Delete original request',
		},
		setNotFeasible: {
			title: 'Set Not Feasible',
			useTemplate: 'Use template',
			selectReason: 'Select a reason',
			engReason: 'Engineering reason',
			templates: {
				reason1: {
					title: 'Preferred antenna selection bigger than the PCB dimensions​.',
					description: 'Your request cannot be executed because the preferred antenna you selected does not fit the PCB dimensions of your device. We recommend considering the NN0X-XXX Virtual Antenna® instead, or enlarging the PCB width up to XX mm.​'
				},
				reason2: {
					title: 'PCB dimensions too small for LTE bands',
					description: 'Your request cannot be executed because the PCB dimensions are too small to achieve reasonable performance in your selected wireless service.​'
				},
				reason3: {
					title: 'PCB dimensions too small for all the bands required (#antennas)',
					description: 'Your request cannot be executed because the PCB dimensions are too small to accommodate all the antennas needed in the design, while maintaining reasonable performance for covering all the requested wireless services.​​'
				},
				reason4: {
					title: 'PCB too big',
					description: 'Your request cannot be executed because the PCB dimensions are too large. We will contact you to ensure your requirements are addressed.​​'
				},
				reason5: {
					title: 'Combination of two protocols none common​ (f.e.: 2 antennas for GNSS, 2 antennas for LoRa​)',
					description: 'Your request cannot be executed because the combination of wireless services is not allowed. We will contact you to ensure your requirements are addressed.​​'
				}
			},
			confirm: {
				title: 'Caution',
				text: 'Do you want to overwrite the current reason?'
			}
		},
		customerService : {
			title: 'New Customer Service',
			save: 'Save Service',
			serviceType: 'Service Type',
			customerEmail: 'Customer email',
			createOrUseProject: {
				decision: 'Create a new project or add the service to an existing one',
				new: 'New',
				existing: 'Existing',
				selectExistingProject: 'Select existing project to add the service',
				placeholder: 'Select the project',
				projectCreated: 'Project information saved',
				addProject: 'Add New Project',
				updProject: 'Update Project'
			},
			createOrUseService: {
				decision: 'Create a new request or reuse existing results',
				formID: 'Form ID of the existing results',
				designName: 'Design name',
				serviceCreated: 'Service information saved',
				addService: 'Add Service',
				updateService: 'Update Service'
			}
		}
	},
	generic: {
		comingSoon: 'Coming soon',
		loading: 'Loading',
		options: 'Options',
		delete: 'Delete',
		footer: {
			copyright: '© Ignion, S.L. 2024. All rights reserved.',
			cookiesPolicy: 'Cookies Policy',
			privacy: 'Privacy',
			terms: 'Terms'
		}
	}
} satisfies BaseTranslation

export default en
