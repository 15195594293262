/* eslint-disable @typescript-eslint/no-unused-expressions */
import { useContext, useEffect, useRef } from 'react'
import { Tooltip } from 'primereact/tooltip'
import clsx from 'clsx'
import type {
  MenuProps,
  MenuModel,
  Breadcrumb,
  BreadcrumbItem,
} from '../types/types'
import { LayoutContext } from './context/layoutcontext'
import { MenuProvider } from './context/menucontext'
import AppMenuitem from './AppMenuitem'


const AppSubMenu = (props: MenuProps) => {
  const { layoutState, setBreadcrumbs, isSlim } = useContext(LayoutContext);
  
  const tooltipRef = useRef<Tooltip | null>(null)

  const { model } = props

  const generateBreadcrumbs = (model: MenuModel[]) => {
    const breadcrumbs: Breadcrumb[] = [];

    const getBreadcrumb = (item: BreadcrumbItem, labels: string[] = []) => {
      const { label, to, items } = item;

      label && labels.push(label);
      items?.forEach((_item) => {
        getBreadcrumb(_item, labels.slice());
      });
      to && breadcrumbs.push({ labels, to });
    };

    model.forEach((item) => {
      getBreadcrumb(item);
    });
    setBreadcrumbs(breadcrumbs);
  };
  
  useEffect(() => {
    if (tooltipRef.current) {
      tooltipRef.current.hide();
      (tooltipRef.current as any).updateTargetEvents();
    }
  }, [layoutState.overlaySubmenuActive]);

  useEffect(() => {
    generateBreadcrumbs(model);
  }, []);

  return (
    <MenuProvider>
      <ul className="layout-menu">
        {model.map((item, i) => {
          return !item.seperator ? (
            <AppMenuitem item={item} root index={i} key={item.label} />
          ) : (
            <li className="menu-separator" />
          );
        })}
      </ul>
      <div className="layout-menu layout-menu-logout">
        <div className="layout-root-menuitem">
          <a href="https://ignion.io/" target="_blank" className="menuitem-ignion" rel='noreferrer'>
            <img className={clsx('nn', isSlim() ? '' : 'hide')} src="/images/logo/nn.svg" alt='NN Ignion' />
            <img className={clsx('powered', isSlim() ? 'hide' : '')} src="/images/powered_ignion.svg" alt='Ignion' />
          </a>
        </div>
      </div>
      <Tooltip className="ignion-menu-tooltip" ref={tooltipRef} target="li:not(.active-menuitem)>.tooltip-target" />
    </MenuProvider>
  );
};

export default AppSubMenu;
