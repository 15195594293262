import { ReactNode } from 'react'
import { useSetMessageReadMutation } from '@/store/api/ignion/message'
import { Message, NotificationMessageData } from '@/types/response/messages'
import { User } from '@/types/response/user'
import { Avatar } from 'primereact/avatar'
import clsx from 'clsx'
import { IconButton } from '../common/IconButton'
import { IconCheck } from '../common/IconButton/consts'

type NotificationMessageProps = {
  user?: User
  children?: ReactNode
  notification: Message<NotificationMessageData>;
};

export default function NotificationMessage({ user, notification, children }: Readonly<NotificationMessageProps>) {

  const [setMessageRead] = useSetMessageReadMutation();

  return (
    <div className={clsx('notification', notification.read && 'notification-read')}>
      <div className="notification-avatar">
        {
          user !== undefined ?
            <Avatar key={user?.email} label={user?.avatarUrl !== undefined ? user.name?.charAt(0)?.toUpperCase() : undefined} image={user?.avatarUrl} size="normal" shape="circle" title={user?.name} /> :
            <img src="/images/oxion-avatar.svg" alt="Oxion" />
        }
      </div>
      <div className="notification-content">
        {children}
        <div className="notification-date">{new Date(notification.createdAt).toDateString()}</div>
      </div>
      <div className="notification-actions">
        <IconButton handleClick={() => setMessageRead({messageId: notification.id})}><IconCheck /></IconButton>
      </div>
    </div>
  )
}