import { useI18nContext } from '@/i18n/i18n-react'


export default function SignInHeader() {
  const { LL } = useI18nContext()
  return (
    <div className='flex flex-column justify-content-start'>
      <div>
        <h2 className="Oxion-h2">{LL.signin.header()}</h2>
      </div>
    </div>
  );
}