export interface MessageData {
}

export enum MessageType {
  NOTIFICATION = 'NOTIFICATION',
}

export enum NotificationType {
  WELCOME = 'WELCOME', 
  SERVICE_DONE = 'SERVICE_DONE', 
  SERVICE_DENY = 'SERVICE_DENY', 
  PROJECT_UPDATED = 'PROJECT_UPDATED', 
  PROJECT_SHARED = 'PROJECT_SHARED',
  NOT_FEASIBLE = 'NOT_FEASIBLE',
  ERROR = 'ERROR',
  RELEASE_NOTE = 'RELEASE_NOTE'
}

export interface Message<T extends MessageData>{
  id: string
  type: MessageType
  expireAt: number
  createdAt: number
  read: boolean
  data: T
}


export interface NotificationMessageData extends MessageData {
  type: NotificationType  
  user?: string
}

export interface EntityNotificationMessageData extends NotificationMessageData {
  orgId: string
  entityId: string
}

export interface ServiceNotificationMessageData extends EntityNotificationMessageData {
  projectId: string
}

export interface ReleaseNoteNotificationMessageData extends EntityNotificationMessageData {
  version: string
}