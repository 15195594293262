export enum AsyncStatus { IDLE, LOADING, ERROR }
export interface UserState {
  status?: AsyncStatus;
}

export enum UserRoles {
  ENGINEER = 'Developer and/or Engineer',
  ENGINEERING_MANAGER = 'Engineering Manager',
  PRODUCT_MANAGER = 'Product Manager',
  BUSINESS_DIRECTOR = 'Business Director or Manager',
  STUDENT = 'Student',
  OTHER = 'Other'
}

// Initial state
export const initialState: UserState = {
  status: AsyncStatus.LOADING,
};

export interface UserData {
  username: string;
  company: string;
  role: string;
  givenName: string;
  familyName: string;
}
