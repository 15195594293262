'use client'

import {
  configureStore,
  combineReducers,
  ThunkAction,
  Action,
  Reducer,
  AnyAction,
} from '@reduxjs/toolkit'
import { versionSlice } from '@/store/slice/version'
import { userSlice } from '@/store/slice/user'
import { messageSlice } from '@/store/slice/messages'
import { layoutConfigSlice } from '@/store/slice/layoutConfig'
import { reportStepSlice } from '@/store/slice/dynamicForm'
import { parserSlice } from '@/store/slice/parser'
import { featureFlagsSlice } from '@/store/slice/featureFlags'
import { createWrapper } from 'next-redux-wrapper'
import {
  persistReducer, persistStore,
  FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER,
} from 'redux-persist'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { rtkQueryErrorLogger } from './middleware/queryErrorLogger'
import { baseApi } from './api/ignion/_base'

const appReducer = combineReducers({
  [versionSlice.name]: versionSlice.reducer,
  [userSlice.name]: userSlice.reducer,
  [messageSlice.name]: messageSlice.reducer,
  [layoutConfigSlice.name]: layoutConfigSlice.reducer,
  [reportStepSlice.name]: reportStepSlice.reducer,
  [parserSlice.name]: parserSlice.reducer,
  [featureFlagsSlice.name]: featureFlagsSlice.reducer,
  [baseApi.reducerPath]: baseApi.reducer,
});

const rootReducer: Reducer = (state: AppState, action: AnyAction) => {
  if (action.type === 'version/clearStore') {
    // this applies to all keys defined in persistConfig(s)
    AsyncStorage.removeItem('persist:root')
    return appReducer({} as AppState, action)
  }
  return appReducer(state, action)
}

const makeConfiguredStore = () =>
  configureStore({
    reducer: rootReducer,
    devTools: true,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(baseApi.middleware)
  });

export const makeStore = () => {
  const isServer = typeof window === 'undefined'
  if (isServer) {
    return makeConfiguredStore();
  } 
    
  const persistConfig = {
      key: 'root',
      whitelist: ['version', 'parsedJson', 'layoutConfig', 'dynamicForm'], // make sure it does not clash with server keys
      storage: AsyncStorage,
      blacklist: [baseApi.reducerPath, 'featureFlags'],
    };
    const persistedReducer = persistReducer(persistConfig, rootReducer);
    const store: any = configureStore({
      reducer: persistedReducer,
      devTools: process.env.NODE_ENV !== 'production',
      middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
          serializableCheck: {
            ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
          },
        }).concat(baseApi.middleware).concat(rtkQueryErrorLogger)
    });
    store.__persistor = persistStore(store);
    return store;

}


export type AppStore = ReturnType<typeof makeStore>
export type AppDispatch = AppStore['dispatch']
export type AppState = ReturnType<AppStore['getState']>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  AppState,
  unknown,
  Action
>

export const wrapper = createWrapper<AppStore>(makeStore)

