import { useRouter } from 'next/router'
import { Message, ServiceNotificationMessageData } from '@/types/response/messages'
import { useI18nContext } from '@/i18n/i18n-react'
import { useSetMessageReadMutation } from '@/store/api/ignion/message'
import LinkButton from '@/components/common/LinkButton'
import NotificationMessage from '../NotificationMessage'

type NotFeasibleNotificationProps = {
  notification: Message<ServiceNotificationMessageData>;
};

export default function NotFeasibleNotification({ notification }: Readonly<NotFeasibleNotificationProps>) {
  const router = useRouter();
  const { LL } = useI18nContext();

  const [setMessageRead] = useSetMessageReadMutation();

  return (
    <NotificationMessage notification={notification}>
      <div className="notification-text not-feasible">
        {LL.message.notification.notFeasible.msg()}
        <LinkButton label={LL.message.notification.notFeasible.link()} handleClick={() => {
          setMessageRead({ messageId: notification.id });
          router.push(`/organization/${notification.data.orgId}/project/${notification.data.projectId}?serviceId=${notification.data.entityId}`, undefined, { shallow: true })}} />
      </div>
    </NotificationMessage>
  )
}